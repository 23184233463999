.footer-border {
  border-top: 1px solid #b5cee4;
  padding: 1rem;
}

.footer-section {
  padding: 1rem 0;
  display: flex;
  justify-content: space-around;
}

.footer-legal-mobile {
  display: none;
}

.footer-img {
  width: 233px;
  height: 86px;
  margin: 0 388.5px 0 0;
  margin-left: 0 !important;
}

.footer-content {
  margin: 14px 0 46px 42px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b5cee4;
}

.img-profile {
  height: 2rem;
  width: 2rem;
  margin-left: 0 !important;
}

.footer-title {
  padding: 0 5px 0 11px;
}

.footer-text {
  font-weight: bold;
  line-height: 2.8;
  padding: 0 5px 0 8px;
}

.footer-text-1 {
  font-weight: bold;
  padding: 0 5px 0 8px;
}

.footer-legal {
  display: flex;
  justify-content: left;
  a:nth-child(2) {
    margin: 0 1rem;
  }
}

.legaly {
  justify-content: center;
}

.softtek-info {
  right: 5%;
  position: absolute;
  @media (max-width: 520px) {
    font-size: 12px;
    margin-top: 30px !important;
    right: 0;
  }
}

.footer-condiciones {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.footer-privacidad {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.footer-Softtek {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.footer-logo {
  width: 88px;
  margin: -11px 7px 0 0 !important;
}

.footer-logo-stk {
  width: 63px;
  margin: -11px 7px 0 0 !important;
}

.footer-contact {
  margin: 1.5rem;
  display: flex;
  width: 375px;
}
.footer-location-square {
  margin: 3rem 1rem;
  justify-content: space-evenly;
  display: flex;
}

.contact-mobile {
  align-items: center;
  align-content: center;
  display: grid;
}
.footer-pacto {
  width: 286px;
  height: 8px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin: 0 10px;
}

.footer-location {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.footer-phone {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.footer-email {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.footer-icons {
  margin: 0 10px;
}

.footerLight-color {
  background-color: #f2f2f2;
}

.footerLight-section {
  padding: 0;
}

.footerLight-title {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e3250;
}

.footerLight-logo {
  width: 76px;
  height: 91px;
  margin-left: 35px !important;
  margin-bottom: 10px;
}

.footerLight-contact {
  margin: 14px 0 20px 45px;
}

.footerLight-pacto {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e3250;
}

.footerLight-location {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #1e3250;
}

.footerLight-phone {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.44;
  letter-spacing: normal;
  text-align: left;
  color: #1e3250;
}

.footerLight-email {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #1e3250;
}

.footerLight-icons {
  color: #b5cee4;
  margin: 0 1rem 0 0;
}

.footerLight-bg {
  background-color: #fff;
  padding: 2rem 0;
}

.footerLight-content {
  margin: 14px 0 46px 42px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131a27;
}

.footerLight-text {
  font-weight: bold;
  line-height: 2.8;
}

.footerLight-legal {
  display: flex;
  justify-content: space-evenly;
}

.footerLight-condiciones {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131a27;
}
.footerLight-privacidad {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131a27;
}
.footerLight-Softtek {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131a27;
}

.footer-street-link {
  color: white !important;
  display: contents;
  transition: all 0.3s ease;
  &:hover {
    color: #ccc !important;
  }
}

.footer-map{
  padding: 0px 0 0px 11px;
}

.color-menu {
  color: #709ec9;
}
.dropdown-subitem {
  padding-left: 24px !important;
  font-weight: 200;
}

.underconstruction-container {
  color: #131a27;
  text-align: center;
  font-size: 18px;
  margin: 0;
  padding: 9px 0 0 0;
  max-width: 100% !important;
  position: fixed;
  z-index: 1;
  top: 92px;
  margin-right: 0 !important;
  background: #f3dd11;
  // Medium devices (tablets, 768px and up) 2
  @media (min-width: 1800px) {
    top: 107px;
  }
}

.underconstruction-img {
  height: 28px !important;
  width: auto;
  margin: -3px 3px 1px 0 !important;
}

.guides-title{
  color: #666;
}

.guides{
  min-height: 90vh;
    background: rgb(245, 245, 245);
    padding: 50px 30% 40px;
    
}

.guides-option{
  padding: 0;
}

.circle-dot{
  margin-left: 80px;
}

.note-participate{
  margin-left: -50px;
}