.result-grid {
  display: flex;
  justify-content: space-between;
}

.result-title {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 41px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131a27;
  margin: 3rem 2.8rem 0;
}

.result-info {
  display: flex;
  justify-content: space-evenly;
}

.result-svg {
  width: 13%;
}

.result-gta {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f49a2d;
  padding: 1rem 0;
}
