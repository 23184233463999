.company-square-bottom {
  height: 111px;
  display: grid;
  align-items: center;
  vertical-align: bottom;
  justify-items: center;
  text-align: center;
  border-radius: 0px 0px 18px 18px;
  background-color: #53779a;
  margin-left: 12px;
}

.company-square-bottom-small {
  height: 86px;
  display: grid;
  align-items: center;
  vertical-align: bottom;
  justify-items: center;
  text-align: center;
  border-radius: 0px 0px 18px 18px;
  background-color: #b9d3e8;
  margin-left: 12px;
}

.company-card-top-bar {
  height: 124px;
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: 18px 18px 0px 0px;
  background-color: '#f2f2f2';
  color: #1e3550;
}

.company-card-img {
  height: 67px;
  width: auto;
  justify-content: center;
  margin-left: 138px !important;
  margin-top: -20px;
}
.company-card-img2 {
  height: 67px;
  width: auto;
  justify-content: center;
  // margin-left: 138px !important;
  margin-top: 20px;
}
.company-card-img-sumate {
  height: 77px;
  width: auto;
  justify-content: center;
  margin-left: 135px !important;
  margin-top: -19px;
  margin-bottom: -24px;
}

.company-card-img-home {
  height: auto;
  width: 150px;
  justify-content: center;
  align-content: center;
  display: grid;
  align-items: center;
  margin-left: 13px;
  margin-top: 24px;
}

.home-points-text {
  @media (max-width: 520px) {
    font-size: 18px;
  }
}

.company-card-top-bar-small {
  height: 55px;
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: 18px 18px 0px 0px;
  background-color: '#f2f2f2';
  color: #1e3550;
}

.company-card-top-bar-2 {
  width: 577px;
  height: 54px;
  margin: 0 0 17.3px;
  border-radius: 18px 18px 0px 0px;
  background-color: '#f2f2f2';
  color: #1e3550;
  text-align: center;
}

.company-card-list-top-bar {
  width: 269px;
  height: 54px;
  margin: 5px 0 5px 0px;
  color: white;
  border-radius: 18px 18px 0px 0px;
}

.company-card-medal {
  width: 69px;
  height: 69px;
  padding: 14px 20px 16px 21px;
  border-radius: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.company-card-body-small {
  width: 380px;
  display: inline-block;
  margin: 39px -45px 65px 0px;
  border-radius: 18px;
  height: 125px;
  background-color: #b9d3e8;

  @media screen and (max-width: 400px) {
    width: 90vw;
  }
}
.company-header-gta-title-2-small {
  @media screen and (max-width: 400px) {
    font-size: 18px !important;
    padding: 0px !important;
  }
}

.company-square-bottom-top {
  display: grid;
  justify-items: center;
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  color: white;
  border-radius: 0px 0px 18px 18px;
  margin-left: 12px;
}
.company-square-bottom-top:hover {
  color: #e7f4ff;
}

.company-card-body-top {
  width: 577px;
  height: 207px;
  display: inline-block;
  margin: 39px 39px 5px 0px;
  border-radius: 18px;
  background-color: #fff;
}

.company-card-company-name {
  width: 100%;
  display: block;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 25px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #131a27;
}

.company-header-number {
  text-align: center;
  margin: 5px 0 0 7px;
}

.company-list-header-number {
  float: left;
  margin: 5px 0 0 9px;
}

.company-header-front {
  padding: 8px 15px 2px 15px;
  margin: 0px 15px;
  text-align: right;
  display: flow-root;
}
.company-header-front-small {
  // padding: 8px 15px 2px 15px;
  // margin: 0px 15px;
  text-align: right;
  display: flow-root;
}
.company-list-header-front {
  padding: 8px 15px 2px 24px;
  margin: 0px 15px;
  justify-content: center;
  text-align: right;
  display: flow-root;
}

.company-header-gta-img {
  width: 40px;
  height: auto;
  margin: -3px 0px 0 -51px;
  margin-left: -50px !important;
}

.company-list-header-gta-img {
  width: 54px;
  height: auto;
  margin: -6px 0px 0 -51px;
  margin-left: -50px !important;
}

.bold {
  font-weight: bold;
}

.font-size-2 {
  font-size: 18px;
}

.company-puntos {
  font-size: 38px;
  font-weight: bold;
  margin-top: 25px;
}

.company-logo-company-img {
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  margin-left: auto !important;
  margin-right: auto;
  max-height: 100px;
  width: auto;
}

.company-card-medal {
  width: 69px;
  height: 69px;
  margin: -70px 0px 0px 150px;
  border: 0.5px solid #8a8a8a05;
  border-radius: 100%;
  padding: 17px 0 0 24px;
  box-shadow: 0 3px 6px #ccccccc4;
}

.company-header-gta-title {
  font-size: 18px;
  font-weight: 400;
}

.company-header-gta-title-2-small {
  font-size: 24px;
  color: 070808;
  padding: 6px 65px 7px 65px;
  font-weight: 400;
  line-height: 32px;
}

.company-list-header-gta-title {
  font-size: 12px;
  font-weight: 400;
}

.company-card-medal-number {
  color: #000000;
  font-weight: 400;
  font-size: 25px;
  padding: 10px 0 0 0;
}

.company-card-square-up {
  margin-left: -12px;
}
.company-card-square-up2 {
  margin-left: 113px;
  /* display: inline; */
  /* justify-content: center; */
  /* align-items: center; */
  /* align-content: center; */
  text-align: center;
  /* margin-left: 37px; */
  padding-left: 105px;
  padding-top: 16px;

  @media (max-width: 520px) {
    padding-left: 0;
    margin-left: 20%;
    margin-top: -20px !important;
  }
}

// Podioum list
.Rectngulo-560 {
  width: 885px;
  display: block;
  height: 66px;
  margin: 10px 68px 18px 25px;
  padding: 0 0 0 19.2px;
  border-radius: 5px;
  background-color: #fff;
}

img.company-list-logo-company-img.justify-content-center {
  height: 52px;
  float: left;
  margin-left: 10px !important;
}

.Rectngulo-692 {
  width: 304px;
  height: 66px;
  margin: 0 0 0 87px;
  padding: 7px 18px 2px 30.9px;
  border-radius: 5px;
  float: right;
  background-color: #0091d1;
}

.company-list-header-front {
  padding: 8px 15px 2px 15px;
  margin: 0px 15px;
  text-align: right;
  float: right;
  display: flow-root;
}

.company-container-rank-list {
  padding: 0px 40px 0px 52px;
}

tr {
  border: none;
}

tbody > tr {
  background: white !important;
  border-bottom: 5px solid #000 !important;
  vertical-align: middle;
  margin-bottom: 10px !important;
}

tbody > tr > td {
  // background: white !important;
  border: none !important;
  padding-bottom: 25px;
}
tbody > tr > td:first-child {
  border-radius: 8px 0px 0px 8px !important;
}
tbody > tr > td:last-child {
  border-radius: 0px 8px 8px 0px !important;
}
.imgRankingList {
  height: 66px;
  margin-top: -15px;
  width: auto;
  float: left;
  margin-left: 0px !important;
}

span.text-h1 {
  line-height: inherit !important;
  margin-bottom: 25px !important;
}
