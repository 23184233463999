.ranking-grid {
  margin: 3rem 0;
  display: flex;
  justify-content: center;
}

.ranking-contain {
  margin: 30px;
}

.ranking-title {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 55px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 60px;
  letter-spacing: normal;
  text-align: left;
  padding-left: 18px;
  padding-right: 18px;
  color: #709ec9;
}

.ranking-subtitle {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 24px;
}

.ranking-form .error-message {
  margin-top: 5px !important;
}

.filter-rank {
  color: #fff !important;
}
