.filter-label-dark {
  font-weight: 300;
}

.filter-inline-search-dark {
  color: white;
}

.form-icon-map-marker {
  position: absolute;
  margin: 15px 0 0 9px;
  height: 18px;
  display: block;
  width: auto;
}
 
.underconstruction-col-left{
  padding: 26px 18px 0px 19px;
  max-width: 394px
}

.underconstruction-icon{  
  color: white;
  font-size: 34px;
}

.underconstruction-a{
  position: absolute;
  top: -8px;
  right: 9px;
  color: white;
  font-size: 48px;
  cursor: pointer;
   
  /* Safari */
  -webkit-transform: rotate(-45deg);

  /* Firefox */
  -moz-transform: rotate(-45deg);

  /* IE */
  -ms-transform: rotate(-45deg);

  /* Opera */
  -o-transform: rotate(-45deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

  transition: all .3s ease;

  &:hover{
    color: #515151;
  }

}

.underconstruction-img{
  height: 487px;
  margin: -27px 0 -27px 0;
  object-fit: cover;
  border-radius: 0 18px 18px 0;
}
.underconstruction-title{
  font-size: 49px;
  line-height: 45px;
}
.underconstruction-text{
  font-size: 29px; 
  font-weight: 300;
}

.snackbar-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
input[id='ubication'] {
  padding-left: 31px;
  padding-top: 7px;
}
.snackbar-container {
  width: 736px;
  box-shadow: 7px 7px 18px -6px #1a273d42;
  border: solid 1px #e0e7ff;
  background-color: #ffffff;
  padding: 1rem 2rem;
  margin: 0 0.5rem;
}

.error-container {
  border-left: 4px solid red;
}

.info-container {
  border-left: 4px solid #2b89e9;
}

.success-container {
  border-left: 4px solid #4cd16d;
}

.warning-container {
  border-left: 4px solid #ffbc22;
}

.snackbar-container > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.snackbar-info-container {
  display: flex;
  align-items: center;
}

.snackbar-info-container > div > h5:nth-child(1) {
  margin: 0 0 0.5rem 0;
}

.snackbar-info-container > div > h5:nth-child(2) {
  margin: 0;
}

.snackbar-container > div > div:nth-child(1) {
  margin-right: 1rem;
}

.snackbar-icon {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 1.3rem;
}
.muted-rubik-text-tr {
  border: none !important;
}
.muted-rubik-text-td {
  border: none;
  vertical-align: top;
  padding-right: 5px;
  height: auto;
  font-weight: bold;
  padding-bottom: 0px;
  line-height: 18px;
}
.snackbar-icon-alert {
  font-size: 34px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 1.4rem;
}

.error-snackbar-icon {
  color: red;
}

.info-snackbar-icon {
  color: #2b89e9;
}

.success-snackbar-icon {
  color: #4cd16d;
}

.warning-snackbar-icon {
  color: #ffbc22;
}

.close-snackbar-icon {
  font-size: 22px;
  font-weight: 100;
  cursor: pointer;
  margin-right: 4px;
  color: #767676ce;
}

#snackbar-fixed-container {
  position: fixed;
  top: 4rem;
  right: 12px;
  z-index: 111111;
}
.rubik-text {
  font-size: 17px;
  font-weight: 500;
  color: #2e384d;
}

.muted-rubik-text {
  font-size: 15px;
  color: #3e4044ce;
}

@media (max-width: 728px) {
  .snackbar-container {
    width: auto;
  }
}

.toggle-switch {
  position: relative;
  width: 98px;
  margin-top: 1px;
  margin-bottom: 25px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 1px 0 0 8px;
      line-height: 34px;
      font-size: 18px;
      color: white;
      font-weight: 400;
      box-sizing: border-box;
    }
    &:before {
      content: 'Sí';
      padding-left: 19px;
      background-color: rgb(8, 165, 21);
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: 'No';
    padding-right: 15px;
    background-color: #709cc7;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 23px;
    margin: 5px 18px 5px 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
}
