.header-color {
  padding: 2rem 3rem;
  background: #131a27;
  max-width: 100%;
}
.conatiner-light {
  height: 100vh;
  padding-top: 95px;
}

.header-grid {
  padding: 0rem 9rem 0rem 3rem;
}

.header-grid-list {
  padding: 2rem 3rem 0rem 3rem;

  span {
    color: #709ec9;
    font-weight: 600;
  }
}

.header-icon {
  color: #4c698a;
  font-size: 35px;
}

.header-img {
  width: 110%;
  margin: 1rem 0;
  margin-left: 0 !important;
}

.header-title {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #709ec9;
}

.header-subtitle {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 20px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.header-impact {
  margin: 1rem 0;
  padding-bottom: 2rem;
  border-bottom: 2px solid #709ec9;
}

.header-impact h5 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #151515;
  margin: 1rem 0;
}

.header-options {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #131a27;
  display: flex;
  justify-content: space-between;
}

.header-implementation {
  margin: 1rem 0;
  padding-bottom: 2rem;
}

.header-implementation h5 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #151515;
  margin: 1rem 0;
}

.header-implementation-options {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #131a27;
  display: flex;
  justify-content: space-between;
}
