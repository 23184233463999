@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400;1,500&display=swap');
.user__profile-picture {
  padding-left: 14px;
}
.users__main {
  align-items: center;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  margin: 0px;
  overflow: overlay;
  padding: 22px 0 50px 0;
  height: 100%;
  width: 100vw;
  color: #1e3250;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;

  @media screen and (min-width: 0px) and (max-width: 575px) {
    // padding-top: 750px;
  }

  // @media screen and (min-width: 576px ) and (max-width: 1003px) {
  //     padding-top: 325px;
  // }

  // @media screen and (min-width: 1004px ) and (max-width: 11003px) {
  //     padding-top: 370px;
  // }
}

svg {
  max-height: 95%;
}

.auth__main_table {
  align-items: center;
  // background-color: $primary;8
  display: flex;
  justify-content: center;
  margin: 0px;
  overflow: overlay;
  padding: 82px 0 50px 0;
  height: 100%;
  width: 100vw;

  @media screen and (min-width: 0px) and (max-width: 575px) {
    padding-top: 110px;
  }
}

.users__box-container {
  background-color: #fff;
  box-shadow: 3px 5px 20px 0px #3636361a;
  border-radius: 2px;
  padding: 25px;
  width: 100%;
  max-width: 1200px;
  // margin: 0 12%;
  margin-top: 6px;
  border-radius: 10px;
  display: flex;
}

.form-profile-space {
  margin-top: -18px;
  margin-bottom: 31px;
  @media screen and (max-width: 991px) {
    margin-top: -5px;
  }
}

.users__box-container-join {
  background-color: #f4f4f4;
  // box-shadow: 3px 5px 20px 0px #3636361a;
  border-radius: 2px;
  padding: 25px 50px;
  width: 100%;
  max-width: 914px;
  // margin: 0 12%;
  margin-top: 6px;
  border-radius: 10px;
  display: flex;
}

.users__box-container-user {
  // background-color: #131a27;
  // border-radius: 2px;
  // padding: 0px 50px;
  width: 100%;
  // max-width: 1160px;
  // margin: 0 12%;
  // margin-top: 6px;
  // border-radius: 10px;
  // display: flex;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  float: left;
  margin-right: 15px;
  margin-top: -7px;
  margin-bottom: 22px;
}
.join-h5-title {
  color: #f2f2f2;
}
.join-h5-title-p {
  color: #f2f2f2;
  margin-top: 30px;
}
.join-h5-text-p {
  color: #f2f2f2;
  font-weight: 200;
}
a:hover {
  color: #709dc8;
}

.join-h5-text-p2 {
  color: #f2f2f2;
  font-weight: 200;
  text-decoration: underline;
}
.join-row-user {
  // background-color: #b5cee4;
  margin: 35px 0px -25px 0px;
  padding: 11px 50px 25px 44px;
  border-radius: 0px 0px 10px 10px;
}

.join-btn {
  border-radius: 2px !important;
  box-shadow: none !important;
  font-size: 23px;
  background: #709dc8 !important;
  border-color: #709dc8 !important;
  padding: 10px 20px 10px 20px;
  width: auto;
}
.join-btn-header {
  box-shadow: 0px 4px 8px 3px #0000000d !important;
  font-size: 23px;
  background: #5a82fe !important;
  border-color: #5a82fe !important;
  padding: 10px 20px 10px 20px;
  width: auto;
}
.join-btn-pdf {
  border-radius: 2px !important;
  box-shadow: none !important;
  font-size: 17px;
  background: #d82436 !important;
  border-color: #d82436 !important;
  padding: 9px 18px 9px 18px;
  transition: all 250ms linear !important;
  color: #f2f2f2;
  width: auto;
  margin-left: 15px;
  float: right;
}

.join-btn-word {
  border-radius: 2px !important;
  box-shadow: none !important;
  font-size: 17px;
  background: #05278c !important;
  border-color: #05278c !important;
  padding: 9px 18px 9px 18px;
  transition: all 250ms linear !important;
  margin-left: 15px;
  color: #f2f2f2;
  width: auto;
  float: right;
}

.join-btn-excel {
  border-radius: 2px !important;
  box-shadow: none !important;
  font-size: 17px;
  background: #198e07 !important;
  border-color: #198e07 !important;
  padding: 9px 18px 9px 18px;
  transition: all 250ms linear !important;
  margin-left: 15px;
  color: #f2f2f2;
  width: auto;
  float: right;
}

.join-h5-text {
  color: #f2f2f2;
  font-weight: 100;
}

.join-user-hr {
  border-top: 3px solid #709dc8;
  margin: 18px 9px 18px 9px;
  opacity: 1;
}

.users__main {
  display: flex;
  .radio-button-gender {
    margin-right: 30px;
  }
  input.form-radio {
    margin: 0 5px;
  }
}

.user__profile {
  @media (max-width: 579px) {
    min-height: 327px;
  }
}

.user__profile-header-cards .user__profile-description {
  @media (min-width: 992px) {
    margin-top: 49px !important;
  }
}

.users__main-join {
  // margin-top: 0px !important;
  // padding-top: 0px !important;
  align-items: center;
  // background-color: $primary;
  // display: flex;
  // justify-content: center;
  // margin: 0px;
  // overflow: overlay;
  // padding: 80px 0 50px 11px;
  height: 100%;
  width: 100%;
  color: #1e3250;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 50px;
  font-weight: 500;
  background-color: white;
}

.ant-tooltip-inner {
  display: none;
}

.register-mobile,
.login__sideimg,
.recoverPwd__sideimg,
.recover__sideimg,
.company-card-img,
.company-card-img-sumate img[alt='profilePicture'] {
  margin: -25px;
  margin-left: 50px !important;
  margin-left: 50px !important;
  border-radius: 0px 10px 10px 0px;
  object-fit: cover;
}

label,
#gender {
  font-weight: 600;
}

.sector__company-row {
  background-color: #b5cee4;
  margin: 35px -50px -25px -25px;
  padding: 30px 50px 60px 50px;
  border-radius: 0px 0px 0px 10px;
}
.logo-join {
  max-height: 45px;
  margin-left: 12px !important;
  margin-right: 2px !important;
}

.join-row {
  background-color: #b5cee4;
  margin: 35px -50px -25px -50px;
  padding: 11px 50px 25px 44px;
  border-radius: 0px 0px 10px 10px;
}

.row div:nth-child(4) label {
  color: $secundario1;
  margin-right: 10px;
}

.register__create {
  display: flex;
  font-weight: 200;
  justify-content: space-between;
  label {
    margin-top: 22px;
  }
  .btn-primary {
    border-radius: 50px;
    width: 90px;
    @media only screen and (max-width: 640px) {
      width: 100%;
  }
  }
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #e8e8e8;
  background: whitesmoke;
}

.table > :not(caption) > * > * {
  padding: 7px;
}

.btn-primary,
.btn-primary.focus,
.btn-primary:focus,
.swal2-styled.swal2-confirm {
  color: #fff;
  background-color: #49a5dd;
  border-color: #4aa7dd;
}

.users__title h3 {
  color: #1e3250 !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
}

.users__title {
  color: darken($color: #1e739b, $amount: 1);
  padding-top: 13px;
  display: flex;
  justify-content: space-between;
  padding: 0px 50px 0px 25px;
}
.register__logos {
  display: flex;
  width: 20%;
}
.register__logos img {
  width: 70%;
}
.users__subtitle {
  font-weight: 300;
  padding-left: 25px;
}

.auth__input {
  color: #545454;
  // border: 0px;
  // border-bottom: 1px solid $light-grey;
  font-size: 16px;
  margin-top: 1px !important;
  margin-bottom: 10px;
  transition: border 0.3s ease;

  &:focus {
    // border-bottom: 1px solid $primary;
    outline: none;
  }
}

.form {
  margin-top: 20px;
}

.form-control:focus {
  border-color: #48a2dc;
  // box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 2%);
}

.form-input-number {
  width: 100px;
}

.general__information {
  padding-left: 25px;
}
.auth__label {
  margin-bottom: 0px;
  font-weight: 400;
}

.auth__alert-error {
  background-color: red;
  border-radius: 5px;
}

.link {
  text-decoration: none !important;
}

.login-or {
  position: relative;
  font-size: 18px;
  color: #aaa;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.hr-or {
  background-color: #f1f1f1;
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: -2px;
  margin-left: -25px;
  background-color: #fff;
  width: 50px;
  text-align: center;
}

.span-total {
  font-weight: bold;
  color: #4aa1db;
}

$white: #fff;
$google-blue: #868686;
$button-active-blue: #1669f2;

label.radio-button-gender {
  // background: #f2f2f2;
  padding: 0px 17px 0px 5px;
  margin-top: 1px;
  margin-bottom: 7px;
  border-radius: 4px;
  font-weight: 400;
}
input[type='radio'] {
  // opacity: 0;
}
.form-input_other {
  width: 95%;
  margin: 0px 0 0 10px;
  margin-top: 10px !important;
}

input[type='radio'] + label {
  font-size: 1em;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  margin: auto 15px auto auto;
}

input[type='radio'] + label span {
  display: inline-block;
  width: 30px;
  height: 10px;
  margin: 1px 0px 0 -30px;
  cursor: pointer;
  border-radius: 20%;
}

input[type='radio'] + label span {
  background-color: #ffffff;
}

input[type='radio']:checked + label span {
  background-color: #660006;
}
.google-btn {
  cursor: pointer;
  margin-top: 5px;
  width: 100%;
  height: 42px;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);

  transition: box-shadow 0.3s ease;

  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $white;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 12px 25% 1px 3px;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }
  &:active {
    background: $button-active-blue;
  }
}

//  Profile

.saved-cards {
  color: #fff;
}

.editor-profile-crop {
  div > div {
    background-color: transparent !important;
    margin-left: 50% !important;
    transform: translateX(-50%);
  }
  img {
    margin: 0;
    margin-left: 50% !important;
    transform: translateX(-50%);
    margin-top: 20px;
  }
  button {
    margin-left: 50% !important;
    transform: translateX(-50%);
    margin-top: 20px;
  }
}

.user__pfl {
  height: 20%;
}

.user__profile-header-cards {
  z-index: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 25px;
  display: flex;
  height: 160px;

  .input-group {
    width: 500px;
    margin-top: 50px;
    input {
      border-radius: 20px 0 0 20px;
    }
    button {
      background-color: #fff !important;
      border-color: #fff !important;
      i {
        color: #8e8e8e;
      }
    }
  }
  img {
    width: 165px;
    height: 165px;
    object-fit: cover;
    border-radius: 50%;
  }
  .user__icon-plus {
    color: #bad4e9 !important;
    position: relative;
    left: 134px;
    top: -13px;
    :hover {
      color: #93aabd !important;
    }
  }
  .user__profile-description {
    margin-left: 43px;
    margin-top: 6px;
    width: 100%;
    h2 {
      margin-top: -50px;
    }
    h3 {
      font-size: 34px;
      font-weight: 300;
    }
    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.user__profile-header {
  z-index: 0;
  margin-top: 80px;
  margin-left: 25px;
  display: flex;
  .input-group {
    width: 500px;
    margin-top: 50px;
    input {
      border-radius: 20px 0 0 20px;
    }
    button {
      background-color: #fff !important;
      border-color: #fff !important;
      i {
        color: #8e8e8e;
      }
    }
  }
  img {
    width: 165px;
    height: 165px;
    object-fit: cover;
    border-radius: 50%;
  }
  .user__icon-plus {
    color: #bad4e9 !important;
    position: relative;
    left: 160px;
    top: 5px;
    :hover {
      color: #93aabd;
    }
  }
  .user__profile-description {
    margin-left: 43px;
    margin-top: 6px;
    width: 100%;
    h2 {
      margin-top: -50px;
    }
    h3 {
      font-size: 34px;
      font-weight: 300;
    }
    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.user-pfl {
  .row {
    margin: 0 !important;
    padding: 0;
    padding-right: 0 !important;
  }
}
.user__profile-details,
.user__profile-detailss {
  background-color: #eaeaea;
  margin-top: -10px;
  padding-bottom: 130px;
  padding-top: 30px;
  z-index: 1;
  width: 100vw;
  .user__profile-resume {
    .user__profile-ddescription {
      margin-top: 50px;
      margin-left: 53px;
    }
    .line {
      width: 214px;
      height: 1px;
      background-color: #fff;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0px;
    }
    span {
      font-size: 16px;
      font-weight: 300;
    }
    img {
      width: 90px;
      height: 90px;
      margin-top: 90px;
      margin-left: 40px !important;
      margin-bottom: -50px;
    }
  }
}

.user__profile-form {
  margin-left: -6px;
  padding-left: -50px;
}

.profile__form {
  margin-left: 30px;
  padding-bottom: 30px;
  .error-message {
    margin-left: 50px !important;
    color: #000;
  }

  label {
    color: #1e3250 !important;
    margin-left: 40px;
    font-family: 'Flame', 'Roboto', Arial, sans-serif;
    font-weight: bold !important;
  }
  input {
    width: 368px;
    margin-left: 40px;
    height: 54px;
    padding-left: 50px;
    margin-top: -2px !important;
    margin-bottom: 5px;
  }
  button {
    margin-right: -30px;
    float: right;
    margin-top: -120px !important;
  }

  // icons //
  .profile__form .profile__icons {
    z-index: 9;
  }

  .profile__form {
    .select-input__profile,
    .css-b62m3t-container {
      width: 368px;
      margin-left: 40px;
      height: 44px;
      // padding-left: 50px;
      margin-top: 0px !important;
      .css-qc6sy-singleValue,
      .css-14el2xx-placeholder {
        padding-left: 40px;
        margin-bottom: 12px;
        color: #131a27;
      }
      .css-26l3qy-menu {
        color: #131a27;
        z-index: 99;
      }
      .css-319lph-ValueContainer {
        height: 54px !important;
        .css-qc6sy-singleValue {
          margin-bottom: 0;
        }
      }
    }
  }

  .profile__form {
    .field-input__profile input {
      position: relative;
      top: 10px;
    }
  }

  .profile__icons {
    position: relative;
    top: 38px;
    left: 60px;
    width: 50px !important;
    color: #b5cee4;
  }

  .profile__icons__edit {
    z-index: 9;
    position: relative;
    top: 63px;
    left: 60px;
    width: 50px !important;
    color: #b5cee4;
  }

  .icon-right__edit {
    left: 60px;
    top: 38px;
  }

  .icon-right {
    left: 60px;
    top: 38px;
  }
}

.profile__social-media {
  .profile__social {
    img {
      width: 30px;
      margin: 9px;
    }
    a {
      font-size: 20px;
      font-weight: 300;
    }
  }
}

// Contacto
.user__contact {
  background-color: #fff;
}
.user__contact-container {
  background-color: #f2f2f2;
  color: #1e3250;
  width: 380px;

  .user__contact-intro {
    img {
      width: 250px;
      height: auto;
      margin-left: 40px !important;
      margin-top: 30px;
    }
    p {
      margin-top: 50px;
      margin-left: 50px;
      margin-bottom: 50px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .user__contact-details {
    p {
      margin-top: 50px;
      margin-left: 50px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.user__contact-details {
  margin: 0;
  div {
    display: flex;
    img {
      width: 80px;
      height: auto;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    h3 {
      margin-left: 50px;
      font-size: 16px;
      font-weight: bold;
    }
    p:nth-child(1) {
      color: #b5cee4;
      margin-right: -30px;
      font-size: 20px;
    }
    p:nth-child(2) {
      color: #1e3250;
      font-size: 13px;
      font-weight: 300;
      width: 280px;
    }
  }
  div:nth-child(3),
  div:nth-child(4),
  div:nth-child(5) {
    margin-top: -40px;
  }
  div:nth-child(5) {
    padding-bottom: 50px;
  }
}

.user__contact-form {
  background-color: #f4f4f4;
  color: #1e3250;
  padding: 3rem;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.user__contact-intro {
  p:nth-child(3) {
    text-decoration: underline #568fc1;
  }
}

.contact__form {
  .contact__form-info {
    input {
      width: 389px;
    }
  }
  .contact__form-subject {
    input {
      width: 825px;
    }
  }
  h3 {
    color: #131a27;
    font-weight: 500;
    font-size: 23px;
  }
  label {
    font-size: 16px;
    color: #131a27;
    font-weight: 500;
    margin-left: 5px;
  }
  textarea {
    width: 825px;
    height: 140px;
  }
  input {
    height: 53px;
  }
  button {
    width: 825px;
    margin-left: 12px;
  }
}

.user-profile-div-button {
  margin-bottom: 27px;
  margin-left: 50px;
}

.profile_buttons {
  margin-top: 150px;
  width: 84%;
  display: flex;
  @media only screen and (max-width: 640px) {
    margin-top: 150px;
    margin-left: 15px;
  }
}

.profile__form row:nth-child(2) {
  margin-top: 100px !important;
  display: list-item;
  margin-bottom: 27px;
}

button.btn.btn-primary.btn-block.user-profile-button {
  min-width: 156px;
}

.saved-cards {
  color: #fff;
  // padding: 4% 0;
  display: block;
}

.link-to-log-in {
  color: #5a82ff !important;
  font-weight: bold !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.link-to-join {
  color: #fff !important;
  font-weight: 100 !important;
  cursor: pointer;
  // &:hover {
  //   text-decoration: underline !important;
  // }
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px #bad4e9 solid;
  margin-top: -39px;
  margin-left: -45px;
  margin-bottom: -20px;
  span {
    color: #bad4e9 !important;
  }
}

.reactEasyCrop_CropArea {
  border-radius: 50%;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  border-radius: 50%;
}

img.ant-upload-list-item-image {
  margin: 0 !important;
  padding: 0 !important;
}
.ant-upload-list-picture-card-container {
  width: 100px !important;
  height: 100px !important;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: transparent !important;
}

.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #096dd9;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 20px;
  line-height: 1;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture
  .ant-upload-list-item-error
  .ant-upload-list-item-thumbnail
  .anticon
  svg
  path[fill='#e6f7ff'],
.ant-upload-list-picture-card
  .ant-upload-list-item-error
  .ant-upload-list-item-thumbnail
  .anticon
  svg
  path[fill='#e6f7ff'] {
  fill: #fff2f0;
}
.ant-upload-list-picture
  .ant-upload-list-item-error
  .ant-upload-list-item-thumbnail
  .anticon
  svg
  path[fill='#1890ff'],
.ant-upload-list-picture-card
  .ant-upload-list-item-error
  .ant-upload-list-item-thumbnail
  .anticon
  svg
  path[fill='#1890ff'] {
  fill: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .ant-upload-list-item-info::before,
.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .ant-upload-list-item-info
  .anticon-eye,
.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .ant-upload-list-item-info
  .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
  animation-name: uploadAnimateInlineOut;
}
@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl
  .ant-upload-list-item-list-type-text:hover
  .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl
  .ant-upload-list-item-list-type-text:hover
  .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item .anticon-close {
  right: auto;
  left: 4px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
.ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
  right: auto;
  left: 8px;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card
  .ant-upload-list-item-file
  + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}

/*# sourceMappingURL=antd.css.map*/
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.ant-zoom-enter,
.ant-modal.antzoom-appear {
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl
  .ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
.ant-dropdown-button.ant-btn-group
  > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}
.ant-select-multiple
  .ant-select-selection-item-remove
  .ant-select-multiple
  .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-btn-icon-only.ant-btn-sm {
  background-color: transparent !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #91d5ff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #91d5ff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s,
    transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #46a6ff;
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
}
.ant-slider-handle:focus {
  border-color: #46a6ff;
  outline: none;
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.85);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #8cc8ff;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
.ant-slider-rtl .ant-slider-dot {
  margin-right: -4px;
  margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:first-child {
  margin-right: -4px;
  margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:last-child {
  margin-right: -4px;
  margin-left: 0;
}

.form-inputs-sector-company {
  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 768px) {
    margin-top: -60px;
  }
}

@media screen and (max-width: 575px) {
  .contact__form {
    margin-left: -12px;
    button {
      margin-left: 25px !important;
    }
  }
}

.contact__form2 {
  input {
    color: black !important;
  }
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
  padding-right: 0px !important;
}