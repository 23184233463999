.btn-primary,
.btn-primary.focus,
.btn-primary:focus,
.swal2-styled.swal2-confirm {
  color: #fff;
  background-color: #1d7097;
  border-color: #1d7097;

  &:hover {
    background-color: lighten(#5a82fd, $amount: 10);
  }
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: lighten(#1e739b, $amount: 15);
  border-color: #1e739b;
}

// .row {
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   // margin-right: 0px;
//   // margin-left: -15px;
// }

.sweet-progress {
  z-index: 999999;
  position: absolute;
  top: 0;
  .progress {
    height: 5px;
    width: 103%;
  }
}
