.glossary-grid {
  display: flex;
  justify-content: flex-end;
}

.glossary-contain {
  margin: 0px;
}
.glossary-subtitle {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 25px;
  font-weight: 300;
  text-align: left;
  color: #709ec9;
}

.glossary-description {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #fff;
}

.glossary-left_description {
  position: absolute;
  left: 35px;
  width: 595px;
  padding: 0 25px;
}

.glossary-workaxis {
  display: flex;
  padding: 0 40px;
  .glossary-img_title {
    margin: 0 15px;
    img {
      width: 65px;
      margin: 0 !important;
    }
  }
}

.glossary-subtitle_bg {
  background-color: #7c518e;
  width: 287px;
  border-radius: 6px;
}

.glossary_map-img {
  margin-left: 0 !important;
  margin-top: 40px;
  max-width: 90%;
}

.glossary-subs_txt {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  color: #fff;
}

.glossary-line {
  width: 90%;
  height: 2px;
  background-color: #709ec9;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.glossary-general_description {
  margin-left: 35px;
  padding: 0 20px;
  .glossary-description {
    width: 300px;
  }
}
