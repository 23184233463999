
// Pallete colors
$primary    : #e4e4e4;
$dark-grey  : #fff;
$light-grey : #fff;
// $blue       : rgb(240, 240, 240);
$blue: blue;


// Paleta de colores Julia
$primario : #131A27;

// Secundario
$secundario1 : #1E3550;
$secundario2 : #526988;
$secundario3 : #7A9EC6;
$secundario4 : #C0D4E8;
$secundario5 : #709ec9;

// Generales
$general1 : #000000;
$general2 : #4D4D4D;
$general3 : #B3B3B3;
$general4 : #F2F2F2;

// Ejes de trabajo
$objetivo1  : #674476;
$objetivo2  : #C7AF58;
$objetivo3  : #427B6D;
$objetivo4  : #D84847;
$objetivo5  : #50889E;
$objetivo6  : #C0529E;
$objetivo7  : #6CA8D1;
$objetivo8  : #6BB06E;
$objetivo9  : #A68467;
$objetivo10 : #A39F41;

// GTAs
$gta1  : #DC2B44;
$gta2  : #E39C3B;
$gta3  : #449E52;
$gta4  : #BF2C3B;
$gta5  : #E63D39;

$gta6  : #00B0CD;
$gta7  : #FCB526;
$gta8  : #6BB06E;
$gta9  : #A68467;
$gta10 : #A39F41;
$gta11 : #674476;
$gta12 : #C7AF58;
$gta13 : #427B6D;
$gta14 : #D84847;
$gta15 : #50889E;
$gta16 : #C0529E;
$gta17 : #6CA8D1;
$gta18 : #6BB06E;
$gta19 : #A68467;
$gta20 : #A39F41;
