.headerImpl-color {
  padding: 1rem 0px 1rem 0px;
  background: #131a27;
  max-width: initial;
}

.headerImpl-grid {
  padding: 1rem 3rem 0rem 6rem;
}

.headerImpl-icon {
  color: #4c698a;
  font-size: 35px;
}

.headerImpl-title {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #709ec9;
}

.spinner-ods {
  width: 190px;
  height: auto;
  position: absolute;
  top: 30vh;
  left: calc(50vw - 190px);
  z-index: 9999;
  @media (max-width: 768px) {
    width: 128px;
    height: auto;
    position: absolute;
    justify-content: center;
    top: 35vh;
    left: calc(54% - 128px);
    padding: 0px;
    z-index: 9999;
  }
}
.rotate-ods {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(900deg);
  }
}

.headerImpl-subtitle {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 20px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.headerImpl-implementation {
  margin: 1rem 0;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
}
