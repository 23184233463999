@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

:root {
  --main-bg-color: #fff;
  --bg-white: #ffffff;
  --bg-purple: #7367f0;
  --bg-tomato: #ea5455;
  --bg-blue: #0396ff;
  --bg-green: #28c76f;
  --bg-cyand: #32ccbc;
  --bg-yellow: #f8d800;
  --cyan-gradient: linear-gradient(135deg, #90f7ec 10%, #32ccbc 100%);
  --green-gradient: linear-gradient(135deg, #81fbb8 10%, #28c76f 100%);
  --blue-gradient: linear-gradient(135deg, #6b73ff 10%, #000dff 100%);
  --pruple-gradient: linear-gradient(135deg, #ce9ffc 10%, #7367f0 100%);
  --shadow-color: rgba(236, 50, 50, 0.08);
  --hover-shadow-color: rgba(0, 0, 0, 0.05);
}

body {
  background-color: var(--main-bg-color);
  color: #fff;
}

main > .container {
  padding: 60px 15px 0;
}

.nav-agenda a {
  color: #fff;
}

.nav-agenda li {
  padding: 0 35px;
}

/* .nav-agenda li:last-child {
  padding: 0 0 0 70px;
} */
.dropdown-agenda {
  top: 46px;
}

.dropdown-agenda a {
  color: #131a27;
}
