.goodpractices-card-body {
  width: 269px;
  display: inline-block;
  margin: 39px 39px 65px 0px;
  border-radius: 18px;
  background-color: #e5e5e5;
}

.contain-join-title {
  padding: 1.8rem 4rem 1rem 4rem;
  h1 {
    font-family: 'Flame', 'Roboto', Arial, sans-serif;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #709ec9;
  }
  h5 {
    font-family: 'Flame', 'Roboto', Arial, sans-serif;
    font-size: 20px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
}
.join-more-email-img {
  min-width: 165px;
}
.join-footer {
  display: flex;
  padding: 10px;
  border-radius: 0 0 9px 9px;
  margin: 0 0 0 -4px;
  border: 0.5px solid #cccccc63;
  width: 265px;
  min-height: 83px;
  height: 83px;

  @media (max-width: 991px) {
    width: 236px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 266px;
  }
  // 576px  767px
}

.join-footer-left {
  float: left;
  padding: 6px 0px;
  width: 100px;
  color: white;
  text-transform: uppercase;
}
.join-footer-rigth {
  width: 330px;
  float: right;
  padding: 5px 6px 0px 20px;
  font-size: 11px;
  font-weight: 400;
  max-height: 68px;
  display: grid;
  align-items: center;
  justify-items: left;
  color: white;
  text-transform: uppercase;
}

.join-more-btn {
  font-size: 24px;
  margin: 0px auto;
  width: 380px;
  display: grid;
  align-items: center;
  color: #f5f5f5;
  justify-items: center;
  padding: 15px 31px;
  border-radius: 48px;
  box-shadow: 3px 3px 8px -2px #3f3f3f7a;
  background: #699cc6;
  border-color: #699cc6;
  transition: all 0.3s ease;
  &:hover {
    color: #fff;
    background: lighten($color: #699cc6, $amount: 3);
    border-color: lighten($color: #699cc6, $amount: 3);
  }
}
.map-modal-info-btn {
  font-size: 18px;
  width: 300px;
  color: #f5f5f5;
  float: center;
  justify-items: top;
  padding: 5px 5px;
  border-radius: 48px;
  background: transparent;
  border-color: #a1c1d8;
  &:hover {
    color: #fff;
    background: lighten($color: #a1c1d8, $amount: 3);
    border-color: lighten($color: #a1c1d8, $amount: 3);
  }
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.join-more-btn-small {
  font-size: 24px;
  margin: 0px auto;
  display: grid;
  align-items: center;
  color: #f5f5f5;
  justify-items: center;
  padding: 15px 31px;
  border-radius: 48px;
  box-shadow: 3px 3px 8px -2px #3f3f3f7a;
  background: #5a82ff;
  border-color: #5a82ff;
  transition: all 0.3s ease;
  &:hover {
    color: #fff;
    background: lighten($color: #5a82ff, $amount: 3);
    border-color: lighten($color: #5a82ff, $amount: 3);
  }
}

.join__img-footer {
  height: auto;
  max-height: 289px;
  width: 100%;
  margin: 0px !important;
  border-radius: 0;
  padding: 0px 0px 0px 0px;
  background: white;
  z-index: 1;
}

.join-main {
  padding: 24px 14px 2px 14px;
  height: 20px;
  display: grid;
  align-items: center;
  justify-items: center;
  // display: table;
}

.join-name {
  font-size: 17px;
  line-height: 18px;
  padding: 5px 5px 0 5px;
  margin: 10px 0px 0;
  text-align: center;
  margin-top: -50px;
  font-weight: 400;
}

.join-header-front {
  display: flex;
  // display: flow-root;
  padding: 15px 10px 10px 15px;
}

.join-header-gta {
  float: left;
  width: 33px;
  height: auto;
}

.join-header-number {
  float: left;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-top: 0px;
}

.join-header-gta-title {
  font-size: 12px;
  float: left;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
}
.join-header-rigth-number {
  width: 59px;
  text-align: right;
  padding-right: 5px;
  font-weight: 400;
  font-size: 15px;
}
.join-filter-bar__label {
  color: white;
}

.join-inline-search {
  color: #fff;
  font-size: 1.5rem;
  margin: 2rem 4rem 0 -4px;
}
.join-label-select-register {
  // background-color: #f1f1f1;
  margin: 5px 0 24px 0px;
  // width: 445px;
}
.join-user-name {
  color: #709ec9;
  font-weight: 600;
}

.card-box {
  position: relative;
  color: #4d6a8a;
  padding: 20px 10px 40px;
  margin: 40px 0px 20px 0px;
  width: 320px;
  border-radius: 10px;
}
.card-box:hover {
  text-decoration: none;
  color: #4d6a8a;
}
.card-box:hover .icon i {
  font-size: 110px;
  transition: 1s;
  -webkit-transition: 1s;
}
.card-box .inner {
  padding: 5px 10px 0 10px;
}
.card-box h3 {
  font-size: 40px;
  font-weight: bold;
  margin: 0 0 8px 0;
  white-space: nowrap;
  padding: 0;
  text-align: left;
}
.card-box p {
  margin: 4px 560px 0 0px;
  display: grid;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131a27;
  // font-weight: 600;
}
.card-box .icon {
  position: absolute;
  top: auto;
  bottom: -19px;
  right: 34px;
  z-index: 0;
  font-size: 86px;
  color: #c0c0c0;
}
.card-box .card-box-footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  text-align: center;
  padding: 3px 0;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  text-decoration: none;
}
.card-box:hover .card-box-footer {
  background: rgba(0, 0, 0, 0.3);
}
.bg-blue {
  background-color: #dad9d9 !important;
}
.bg-green {
  background-color: #00a65a !important;
}
.bg-orange {
  background-color: #f39c12 !important;
}
.bg-red {
  background-color: #d9534f !important;
}
.join-label-joined {
  color: #070808;
  font-weight: 400;
  font-size: 20px;
  margin-top: -73px;
  line-height: 23px;
}
.join-label-register-empty {
  margin-top: -48px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 15px;
  color: red;
}

.btn-primary-join:hover {
  background: lighten($color: #b94c6d, $amount: 10) !important;
  border-color: lighten($color: #5f2b2f, $amount: 10) !important;
  width: auto;
}

.join-detail-title-popup {
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  color: #719ec9;
  margin-bottom: 10px;
}

.join-more-email-modal {
  font-weight: 300;
  font-size: 26px;
  color: #5a82ff;
}

.join-modal-content {
  width: 800px;
}
.map-info-card-modal-content {
  width: 1150px;
  @media only screen and (max-width: 640px) {
    margin: 16px;

  }
}

.modal-content {
  background-color: #ededed;
}

.join-other-label {
  margin-right: 5px;
  font-weight: 400;
}
.join-other-div {
  display: flow-root;
  width: 532px;
  margin: -13px 0 0 10px;
}
.join-other-input {
  display: inline-block;
  width: 300px;
  z-index: -100;
  margin: -16px 0 0 5px !important;
}
.join-form-businessjointsubypes {
  margin-top: -20px;
  margin-bottom: 20px;
}

.join-more-modal {
  font-size: 25px;
  margin: 16px 0px 0px 0px !important;
  width: 170px;
  display: grid;
  align-items: center;
  color: #f5f5f5;
  justify-items: center;
  padding: 11px 20px;
  border-radius: 48px;
  box-shadow: 3px 3px 8px -2px #3f3f3f7a;
  background: #5a82ff;
  border-color: #5a82ff;
  transition: all 0.3s ease;
  &:hover {
    color: #fff;
    background: lighten($color: #5a82ff, $amount: 3);
    border-color: lighten($color: #5a82ff, $amount: 3);
  }
}
.cards-mobile {
  margin: 3rem 0rem 3rem 0rem;
  padding-left: 5rem;
  padding-right: 1rem;

  @media (min-width: 1892px) {
    padding-left: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .cards-mobile {
    // margin: 1rem 3rem 0 2rem;
    justify-content: center;
    padding-right: 0px;
    padding-top: 8px;
    padding-left: 20px;
    padding-bottom: 50px;
    margin-left: 0px;
  }

  .join-footer {
    width: 236px;
  }

  .mt-auto {
    width: 119%;
    margin-left: -20px;
  }
}
.swal2-styled.swal2-confirm {
  background-color: #5a82ff !important;
  &:hover {
    background-color: lighten($color: #5a82ff, $amount: 8) !important;
  }
}

.map-info-title-popup-right,
.map-info-title-popup-left {
  color: #243451;
  font-size: 25px;
}
.map-info-title-popup-left {
  font-weight: bold;
  float: left;
  width: 400px;
  padding-left: 3px;
  @media only screen and (max-width: 640px) {
    font-size: 20px;
    text-align: left;
    padding-left: 0;
    margin: 0 16px 0 -16px;
  }
}
.map-info-title-popup-right {
  font-weight: 300;
  float: right;
}

.map-img-help-card {
  height: 370px;
  width: auto;
  @media only screen and (max-width: 640px) {
    width: 21pc;
    height: 175px;
    margin: 0 16px 0 -22px;
  }
}

.join-action_form {
  @media screen and (max-width: 575px) {
    .accept-join_check {
      display: grid;
      .radio-button-gender {
        width: 100px;
      }
    }
    .impacted-ods_row {
      flex-direction: column;
      select[name='ods'] {
        width: 20%;
      }
    }
    button {
      width: 40%;
    }
    .join-actions_terns {
      input {
        width: 20px;
      }
    }
    .join-action-checkboxes_form {
      .radio-button-gender {
        width: 100px;
      }
      input {
        width: 20px;
      }
    }
    .row-join_files {
      label {
        width: 100%;
      }
      flex-direction: column;
      width: 40%;
    }
    .impacted-ods_join {
      width: 877px;
    }
    input {
      width: 40%;
    }
    textarea {
      width: 40%;
    }
    select {
      width: 40%;
    }
    label {
      width: 40%;
    }
    .form-radio {
      width: 20px;
    }
    .join-label_responsive {
      width: 205%;
    }
    .join-other-input {
      width: 98%;
    }
  }
}
