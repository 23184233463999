//Mobile small devices
@media (max-width: 575.98px) {
  /// GUIDES 
  .guides{
    padding: 2em;
  }
  .guides-title{
    text-align: center;
  }

  .circle-dot{
    margin-left: 0;
  }

  .note-participate{
    margin-left: 0;
  }
  // ** Navbar y menu fullpage
  .user__profile-header-cards .user__profile-description {
    padding-right: 116px;
    padding-bottom: 39px;
    text-align: center;
  }

  .col-home-card {
    justify-content: center !important;
  }

  .user__profile-header .user__profile-description {
    text-align: center;
    padding-right: 7.1rem;
  }
  

  .user__profile-header .user__icon-plus {
    margin-left: 60vw;
    margin-top: -15px;
    position: sticky;
  }
  .user__profile-picture {
    margin-right: 1px;
    padding-left: 0;
  }
  // GTA ICON
  .gta__img-circle {
    margin-top: 9%;
  }
  .div-text-padding-0 {
    padding-left: 0px;
  }
  .div-filter-map {
    padding-left: 3rem !important;
    padding-right: 2rem;
  }

  #root {
    overflow-x: hidden;
  }
  .home-div-principal {
    height: 1670px;
  }

  .nav-grid {
    display: none;
  }
  .navbar-nav {
    overflow: hidden;
  }
  .navLight-agenda {
    padding: 10px 35px;
    height: 50px;
  }
  .nav-agenda {
    padding: 10px 35px;
    height: 50px;
  }
  .bm-cross-button a {
    color: #000;
    left: 0px;
    top: 10px;
  }
  .bm-menu-wrap {
    left: 0;
    top: 0;
    width: 50%;
    height: 50%;
    background-color: #f0f0f0;
  }
  .full-page-menu {
    margin-left: 10% !important;
  }
  .fullpage-item {
    font-size: 26px !important;
  }
  .bm-menu .bm-item-list a {
    font-size: 26px;
  }
  .navbar-brand {
    position: absolute;
    top: 10px;
  }

  /*end*/
  // ** Footer
  .logo-mobile {
    display: flex;
    justify-content: center;
  }
  .footer-img {
    margin: 0;
    width: 35%;
    height: 50%;
  }
  .footer-title {
    display: none;
  }
  .contact-mobile {
    display: none;
  }
  .footer-location-square {
    display: none;
  }
  .logopact-mobile {
    display: flex;
    justify-content: center;
    // margin: auto;
  }
  .footer-logo {
    width: 25%;
    margin-left: 0;
    position: relative;
    left: 0;
  }
  .footer-logo-stk {
    width: 15%;
    margin: auto !important;
    display: flex;
  }
  .footer-border {
    border-top: none;
  }

  .footer-legal {
    justify-content: space-evenly;
    margin-bottom: 18px;
  }

  .footer-map{
    padding: 0px 0 0px 24px;
  }

  .home-subtitle-content {
    padding: 0rem 4rem 0rem 0rem;
  }
  .home-aquote {
    padding: 2rem 2rem 2rem 5%;
    background: #e5e5e5;
    width: 105%;
    font-size: 19px;
  }

  /*end*/
  // ** Page Contact

  .user__contact-container {
    display: none;
  }
  .user__contact-form {
    width: 105%;
    padding: 2rem 4rem 0 1rem;
  }
  .contact__form h3 {
    margin-left: 0.8rem;
  }
  .contact__form .contact__form-info input {
    margin: 1rem;
    width: 106%;
  }
  .contact__form .contact__form-subject input {
    margin: 1rem;
    width: 106%;
  }
  .contact__form textarea {
    margin: 1rem;
    width: 106%;
  }
  .contact__form label {
    margin-left: 17px;
  }
  .contact__form button {
    width: 100%;
    margin: 2rem;
    h3 {
      margin-left: 1rem;
    }
  }
  // ** Page register
  .register-mobile {
    display: none;
  }
  .users__box-container {
    border-radius: 0;
    padding: 2em 3em 0 1em;
  }
  .users__main {
    padding: 0;
  }
  .sector__company-row {
    background-color: transparent;
    padding: 20px 0px 60px 25px;
    margin: 0;
    border-radius: 0;
  }
  .register__create label {
    margin: 25px 12px 13px 0px;
  }
  .register__create {
    display: grid;
    font-weight: 200;
  }
  //** Page join detail
  .users__box-container-user {
    display: block;
    padding: 0;
    margin-top: 0;
    // max-width: 575px;
  }
  .join-more-btn {
    width: 360px !important;
  }

  // ** Page Login && recover
  .login__sideimg {
    display: none;
  }
  .auth__form {
    margin-left: -25px;
  }
  .auth__main {
    padding: 50px 0 50px 0 !important;
  }
  .auth__box-container {
    border-radius: 0px !important;
    padding: 2rem 3.7rem !important;
  }
  .login__form .login__input:nth-child(2) {
    margin-left: -13px !important;
  }
  .login__form .login__input:nth-child(2) .login__questioni {
    top: 69px !important;
    left: 12px !important;
  }
  .recoverPwd__sideimg {
    display: none;
  }
  .auth__recover {
    width: 106% !important;
  }
  .auth__box-recover-container {
    border-radius: 0px !important;
    padding: 62px 8px 30px !important;
  }
  .auth__box-restore-container {
    border-radius: 0px !important;
    padding: 62px 0px 30px !important;
  }

  .auth__recover {
    padding: 0 !important;
  }
  .recover__password {
    padding: 0px 25px !important;
  }
  // ** Priorization Page
  .conatiner-light {
    height: 110vh;
    padding-top: 0;
  }
  .header-color {
    padding: 0;
  }
  .header-img {
    width: 70%;
    margin: 1rem 0rem;
    margin-left: 3rem !important;
  }
  .header-grid {
    padding: 1rem 4rem 0rem 4rem;
  }
  .header-title {
    font-size: 35px;
  }
  .header-subtitle {
    font-size: 20px;
  }
  .header-options {
    font-size: 20px;
    display: grid;
    justify-content: flex-start;
    span {
      margin: 3px;
    }
  }
  .header-implementation-options {
    font-size: 20px;
    display: grid;
    justify-content: flex-start;
    span {
      margin: 3px;
    }
  }
  .result-title {
    font-size: 30px;
    margin: 3rem 1.7rem 0;
  }
  .cards__row {
    padding: 0px 25px 46px 46px;
    width: 100%;
  }
  .custom-select-filter {
    width: 95%;
  }
  .search-mobile {
    display: flex;
    justify-content: flex-end;
    margin: 0rem 0rem 0rem 4rem;
  }
  // ** Implementation page
  .headerImpl-color {
    padding: 1rem 0rem 0rem 0rem;
  }

  .join-inline-search {
    margin: 2rem 0 0 3rem !important;
  }
  .headerImpl-grid {
    padding: 0rem 1rem 2rem 3rem;
  }
  .headerImpl-title {
    font-size: 35px;
  }
  .headerImpl-subtitle {
    font-size: 20px;
  }
  .headerImpl-img {
    width: 80%;
    margin-left: -2rem !important;
  }
  // .cards-mobile {
  //   margin: 0 1.4rem;
  // }
  // PAGE
  .priorization-page-detail {
    overflow-x: hidden;
  }
  // HEADER CARD DETAIL
  .card__detail-container {
    .row {
      .col-12 {
        display: flex;
        margin: 30px 0;
        margin-left: 40px;
        .Rectngulo-141 {
          width: 100%;
          padding: 0;
          .Contratar-externosas-para-evaluar-los-procesos-de-reclutamiento-con-perspectiva-de-gnero {
            width: 100%;
          }
        }
      }
      .col-3 {
        width: 100%;
        .Rectngulo-141 {
          display: flex;
          width: 100%;
          padding: 0 40px;
          .rectangle-card-detail {
            width: 100%;
            min-width: 122px;
            margin-right: -26px;
            img {
              width: 50px;
              margin-top: -15px;
              margin-left: 0px !important;
            }
            .Diversidad-e-Inclusin-Igualdad-de-gnero {
              font-size: 12px;
              width: 100%;
              margin-top: -30px;
              margin-left: 0px;
            }
            .Lnea-148 {
              margin-top: 5px;
              width: 100%;
            }
          }
          .rectangle-priorization {
            width: 80%;
            font-size: 10px;
            margin-left: 50px;
            .Rectngulo-420,
            .Rectngulo-421 {
              width: 144px;
              height: 20px;
              span {
                font-size: 10px;
              }
              .Elipse-152 {
                margin: -14px 0 5px 111.8px;
              }
            }
          }
        }
      }
    }
  }
  .flashcard-detail-front-div-solution {
    font-size: 19px;
    height: 142px;
  }
  .flashcard-detail-front-title {
    max-width: 194px;
  }
  // CARD
  .card-detail-priorization {
    margin-left: 55% !important;
    transform: translateX(-50%);
    padding-left: 0px !important;
    .row {
      .card-detail-content-front {
        margin-bottom: 20px;
      }
    }
  }
  // COMMENT BOX
  .Rectngulo-406 {
    .row {
      .Like:nth-child(1) {
        margin-left: -30% !important;
      }
      .Like:nth-child(2) {
        margin-left: 50% !important;
        margin-top: -32px;
        font-size: 17px;
      }
      .Like {
        width: 100%;
        padding: 0;
      }
    }
  }

  textarea#comment {
    width: 100%;
  }
  .coomentbox-comment {
    width: 100% !important;
  }
  // PRIORIZATION DETAIL
  .modal-content {
    margin: 50px 0;
  }
  .modal-footer_priorization {
    font-size: 10px;
  }
  .priorization-detail-card {
    margin-left: 6px;
  }
  .modal-body .card-detail-content-front {
    width: 100%;
    margin-bottom: 30px;
    
  }
  .col-xl-3.col-lg-3.col-md-3.col-sm-3.col-12.priorization-rating {
    width: 50%;
    font-size: 9px;
    padding-left: 35px;
  }
  .implementation-title-priorization {
    margin-top: 18px !important;
  }
  .impacto-title-priorization {
    margin-top: -5px !important;
  }
  // END PRIORIZATION DETAIL
  .home-page {
    .row-layout-light {
      .container {
        padding-bottom: 0 !important;
      }
    }
  }
  .home-card-slider {
    .cards__slider-rows {
      margin-bottom: 0 !important;
    }
  }

  .FLASHCARDS-CLIMB {
    font-size: 45px;
  }
  .FC_Mocukup_LandingPage {
    margin: 0;
    padding: 40px 60px 32px 34px;
    height: auto;
    .col-5 {
      width: 100%;
    }
    img.home-banner-front-icon {
      margin-top: 30px;
      margin-left: 35% !important;
    }
  }
  .home-subtitle {
    font-size: 18px;
  }
  .home-main {
    margin-left: 5px;
    padding: 3% 6% 3% 8%;
  }
  .home-main-content {
    padding: 2rem 12% 1% 9%;
  }
  .home-page-circles {
    .col-6 {
      width: 100%;
      margin-left: 5%;
    }
  }
  .home-elipse,
  .home-elipse-light {
    width: 150px;
    height: 150px;
    padding: 65px 19px !important;
    line-height: 2px;
    margin-top: 80px;
    span {
      font-size: 12px;
      margin-top: 13%;
      width: 110px;
    }
    .mapeo-circle {
      margin-top: -20px;
      padding: 0 10px;
    }
    .priorizacion-circle {
      margin-top: -10px;
    }
    .implementacion-circle {
      margin-top: -10px;
      margin-left: 10px;
    }
    .home-text-circle2 {
      margin-left: 30px;
    }
  }
  .home-label-h1 {
    font-size: 45px;
    padding: 0;
    float: none;
  }
  // Matriz de acción
  .home-page-matrix {
    .row {
      display: flex;
      flex-direction: column;
      img.home-img-eje {
        float: none;
        margin-left: 100% !important;
        margin-bottom: 50px;
      }
      .col-5 {
        width: 100%;
        padding: 0 50px;
        .title-h1 {
          margin: 0;
        }
        .text-h1 {
          width: 100%;
          font-size: 18px;
        }
        .text-h1-2 {
          margin-top: 100px !important;
        }
      }
    }
  }
  // CAROUSEL
  .Conoce-las-flashcards-con-mayor-ranking {
    margin-left: -15px;
    font-size: 15px;
  }
  .bwgQCB {
    width: 90% !important;
  }
  .eRmJbc {
    font-size: 1em !important;
    width: 25px !important;
    height: 25px !important;
    min-width: 25px !important;
    line-height: 25px !important;
  }
  .khvUfi {
    font-size: 1em !important;
    width: 25px !important;
    height: 25px !important;
    min-width: 25px !important;
    line-height: 25px !important;
  }

  .cards__slider-rows {
    padding: 0 10px;
    margin-left: -8px;
  }

  .cards__card-dimension {
    // min-width: 365px !important;
    margin-right: 12px;
  }
  // GTA HEADER
  .gta__header {
    padding-top: 0;
    margin-top: -50px;
    h1 {
      font-size: 20px;
    }
    img {
      width: 120px;
      margin-top: 50px;
      margin-left: 12% !important;
    }
    .col-lg-4.logo {
      width: 50vw;
    }
    .col-lg-8.title {
      width: 100vw;
      margin: 0 0 90px 0px;
      h1 {
        margin-top: 30px;
      }
      .gta__text {
        p {
          max-width: 90vw;
          margin-left: -10% !important;
          font-size: 14px;
          line-height: normal;
        }
      }
    }
    .gta__description {
      width: 100vw;
      padding: 0 40px;
      margin-top: -90px;
      h3 {
        font-size: 20px;
      }
      span {
        font-size: 14px;
      }
      li {
        font-size: 14px;
      }
    }
    .gta__list-cards.container {
      margin-top: 0;
    }
  }
  // GTA PAGE
  .gta__page {
    overflow-x: hidden;
  }
  // GTA FRONT CARD
  .gta__containerCards {
    padding: 0 0 0 7%;
  }
  .gta__card-container {
    margin-bottom: -100px;
    .gta__front,
    .gta__back {
      height: 233px;
    }
  }
  // GTA DETAIL CARD BACK
  .gta__mainCard {
    h4 {
      font-size: 12px;
    }
  }
  .back-detail {
    h3 {
      font-size: 10px;
      margin-top: -10px;
    }
  }
  .card-org-detail {
    h2 {
      font-size: 12px;
    }
  }
  // GLOSSARY HEADER
  .glossaryPage {
    .ranking-contain {
      display: flex;
      img {
        position: unset !important;
        width: 100px !important;
        margin-left: 0 !important;
        margin-top: -30px !important;
      }
      h2 {
        font-size: 30px;
        margin-left: 60px !important;
      }
    }
    .glossary-left_description {
      position: unset;
    }
  }
  // GLOSSARY CONTAIN
  .glossary-grid {
    margin-bottom: 70px !important;
    .glossary-contain {
      width: 100%;
      .row {
        margin-left: 0;
        .glossary-left_description {
          margin-left: 50%;
          transform: translateX(-50%);
          width: 100%;
          padding-left: 40px;
        }
      }
    }
  }
  // MAPA MENTAL GLOSARIO
  .glossary_map {
    img {
      margin-left: 0 !important;
    }
  }
  .glossary-workaxis {
    padding: 0;
  }
  .glossary-general_description {
    padding: 0;
  }
  .glossary-workaxis .glossary-img_title {
    margin: 0px 3px;
  }
  .glossary-line {
    margin-left: 52%;
  }
  // ** Ranking page
  .ranking-page {
    .ranking-grid {
      margin: 0;
    }
    .cards__slider-rows {
      margin-left: 10px;
    }
    form.form.ranking-form {
      margin-left: 15px;
      margin-top: -20px;
    }
    .ranking-title {
      font-size: 40px;
      text-align: center;
    }
    .ranking-subtitle {
      font-size: 16px;
    }
  }
  // ** Solution Map Page
  .solution-map-page {
    form {
      .row {
        flex-direction: column;
        .custom-select-filter {
          width: 80%;
        }
      }
    }
    .cards__card-dimension {
      margin-left: 50%;
      transform: translateX(-65%);
    }
  }
  /*********************************************/
  /********** LEGAL DISCLAIMER ***************/
  /*********************************************/
  .post-paragraph {
    padding: 0;
    .row {
      margin-left: 20px;
    }
    p {
      margin-left: 30px !important;
    }
  }
  // ** Profile page
  .user__profile-description {
    h2 {
      margin-top: 8px !important;
    }
  }
  .user__profile-details {
    margin-left: 0;
    padding-bottom: 0;
    .profile__form {
      margin-left: 0px;
      .profile__icons__edit {
        left: 18px;
      }
    }
    .row {
      .col-6 {
        width: 100%;
        input {
          margin-left: 0;
          width: 100%;
        }
      }
    }
    .user-profile-div-button {
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
  .profile__form .profile__form .select-input__profile,
  .profile__form .profile__form .css-b62m3t-container {
    margin-left: 0;
    width: 100%;
  }

  .user__profile-header-cards {
    display: flex;
    margin-top: 0;
    flex-direction: column;
    height: 16rem;
    margin-left: 10px;
    img {
      margin: 0;
      margin-left: 50% !important;
      transform: translateX(-47%);
    }
  }

  .user__pfl,
  .user__profile {
    .user__profile-header {
      display: flex;
      flex-direction: column;
      padding-bottom: 2rem;
      margin-left: 10px;
      .input-group {
        margin-left: 15%;
        width: 100%;
      }
      img {
        margin: 2em 10em 2em 11em;
        // margin-left: 50% !important;
        transform: translateX(-47%);
      }
    }
    .user__profile-detailss {
      margin-top: 0;
      margin-left: 3.4px;
    }
    .user__profile-header {
      margin-top: 0;
    }
    .profile__form {
      width: 100vw;
      padding-top: 0!important;
      padding-bottom: 10px;
      .row {
        .col-6 {
          width: 100%;
          input {
            margin-left: 0px;
            width: 93%;
            margin-bottom: 20px;
            margin-top: -50px !important;
          }
          .profile__icons {
            left: 15px;
            top: 20px;
          }
          label {
            margin-left: 10px;
          }
          .user-profile-div-button {
            margin-left: 50%;
            transform: translateX(-50%);
            button {
              margin-right: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  // SAVED CARDS
  .profile_saved_cards {
    margin-left: 6px;
    form {
      .row {
        flex-direction: column;
        .col {
          margin-left: 3%;
        }
        .custom-select-filter {
          width: 90%;
        }
      }
    }
    .cards__card-dimension {
      margin-left: 56%;
      transform: translateX(-65%);
    }
    .search-mobile {
      justify-content: flex-end;
    }
    .filter-inline-search {
      margin: 1rem 2rem;
    }
  }
  .filter-inline-search {
    margin: 1rem 4.5rem 1rem 5.3rem;
  
  }
  .back__header {
    height: 155vh;
  }
}
//Mobile small devices 2
@media (min-width: 576px) and (max-width: 767.98px) {
  // ** Nav && menuFullpage
  #root {
    overflow-x: hidden;
  }
  .nav-grid {
    display: none;
  }
  .navbar-nav {
    overflow: hidden;
  }
  .navLight-agenda {
    padding: 10px 35px;
    height: 50px;
  }

  .bm-cross-button a {
    color: #000;
    left: 0px;
    top: 10px;
  }
  .full-page-menu {
    margin-left: 10% !important;
  }
  .fullpage-item {
    font-size: 26px !important;
  }
  .bm-menu .bm-item-list a {
    font-size: 26px;
  }
  .navbar-brand {
    position: absolute;
    top: 10px;
  }

  // end
  // ** Footer

  .logo-mobile {
    display: flex;
    justify-content: center;
  }
  .footer-img {
    margin: 0;
    width: 35%;
    height: 50%;
  }
  .footer-title {
    display: none;
  }
  .contact-mobile {
    display: none;
  }
  .footer-location-square {
    display: none;
  }
  .logopact-mobile {
    display: flex;
    justify-content: center;
  }
  .footer-logo {
    width: 30%;
    margin-left: 0;
    position: relative;
    left: 0;
  }
  .footer-logo-stk {
    width: 20%;
    margin-left: 0;
    position: relative;
    left: 0;
  }
  .footer-border {
    border-top: none;
  }

  .footer-legal {
    justify-content: space-evenly;
  }

  // ** Page contact

  .user__contact-container {
    display: none;
  }
  .user__contact-form {
    width: 100%;
    padding-top: 30px;
  }
  .contact__form h3 {
    margin-left: 0.8rem;
  }
  .contact__form .contact__form-info input {
    margin: 1rem;
    width: 106%;
  }
  .contact__form .contact__form-subject input {
    margin: 1rem;
    width: 106%;
  }
  .contact__form textarea {
    margin: 1rem;
    width: 106%;
  }
  .contact__form label {
    margin-left: 17px;
  }
  .contact__form button {
    width: 100%;
    margin: 2rem;
    h3 {
      margin-left: 1rem;
    }
  }
  // end
  // ** Page register

  .register-mobile {
    display: none;
  }
  .users__box-container {
    border-radius: 0;
  }
  .users__main {
    padding: 0;
  }
  .sector__company-row {
    background-color: transparent;
    padding: 20px 0px 60px 25px;
    margin: 0;
    border-radius: 0;
  }
  .register__create label {
    margin: 25px 12px 13px 0px;
  }
  .register__create {
    display: grid;
    font-weight: 200;
  }
  // end
  // ** Page Login && recover

  .login__sideimg {
    display: none;
  }
  .auth__form {
    margin-left: -25px;
  }
  .auth__main {
    padding: 0px 0 50px 0 !important;
  }
  .auth__box-container {
    border-radius: 0px !important;
    padding: 50px !important;
  }
  .login__form .login__input:nth-child(2) {
    margin-left: -13px !important;
  }
  .login__form .login__input:nth-child(2) .login__questioni {
    top: 69px !important;
    left: 12px !important;
  }
  // end
  // ** Priorization Page
  .conatiner-light {
    height: 110vh;
    padding-top: 0;
  }
  .header-color {
    padding: 0;
  }
  .header-img {
    width: 70%;
    margin: 1rem 0rem;
    margin-left: 3rem !important;
  }
  .header-grid {
    padding: 1rem 4rem 0rem 4rem;
  }
  .header-title {
    font-size: 35px;
  }
  .header-subtitle {
    font-size: 20px;
  }
  .header-options {
    font-size: 20px;
    display: grid;
    justify-content: flex-start;
    span {
      margin: 3px;
    }
  }
  .header-implementation-options {
    font-size: 20px;
    display: grid;
    justify-content: flex-start;
    span {
      margin: 3px;
    }
  }
  .result-title {
    font-size: 30px;
    margin: 3rem 1.7rem 0;
  }
  .cards__row {
    padding: 0px 25px 46px 46px;
    width: 100%;
  }
  .custom-select-filter {
    width: 100%;
  }
  .search-mobile {
    display: flex;
    justify-content: flex-end;
  }
  // ** Priorization Page
  .conatiner-light {
    height: 110vh;
    padding-top: 0;
  }
  .header-color {
    padding: 0;
  }
  .header-img {
    width: 70%;
    margin: 1rem 0rem;
    margin-left: 3rem !important;
  }
  .header-grid {
    padding: 1rem 4rem 0rem 4rem;
  }
  .header-title {
    font-size: 35px;
  }
  .header-subtitle {
    font-size: 20px;
  }
  .header-options {
    font-size: 20px;
    display: grid;
    justify-content: flex-start;
    span {
      margin: 3px;
    }
  }
  .header-implementation-options {
    font-size: 20px;
    display: grid;
    justify-content: flex-start;
    span {
      margin: 3px;
    }
  }
  .result-title {
    font-size: 30px;
    margin: 3rem 1.7rem 0;
  }
  .cards__row {
    padding: 0px 0px 46px 46px;
    width: 100%;
  }
  .custom-select-filter {
    width: 100%;
  }
  .search-mobile {
    display: flex;
    justify-content: flex-end;
  }
  // ** Implementation page
  .headerImpl-color {
    padding: 1rem 3rem 0rem 1rem;
  }
  .headerImpl-grid {
    padding: 0rem 1rem 0rem 2rem;
  }
  .headerImpl-title {
    font-size: 35px;
  }
  .headerImpl-subtitle {
    font-size: 20px;
  }
  .headerImpl-img {
    width: 80%;
    margin-left: -2rem !important;
  }
  // .cards-mobile {
  //   margin: 0 1.4rem;
  // }
  // PAGE
  .priorization-page-detail {
    overflow-x: hidden;
  }
  // HEADER CARD DETAIL
  .card__detail-container {
    .row {
      flex-direction: column;
      .col-12 {
        display: flex;
        margin: 30px 0;
        margin-left: 40px;
        .Rectngulo-141 {
          width: 100%;
          padding: 0;
          .Contratar-externosas-para-evaluar-los-procesos-de-reclutamiento-con-perspectiva-de-gnero {
            width: 100%;
          }
        }
      }
      .col-3 {
        width: 100%;
        .Rectngulo-141 {
          display: flex;
          width: 100%;
          padding: 0 40px;
          .rectangle-card-detail {
            width: 25%;
            img {
              width: 50px;
              margin-top: -15px;
              margin-left: 0px !important;
            }
            .Diversidad-e-Inclusin-Igualdad-de-gnero {
              font-size: 12px;
              width: 100%;
              margin-top: -30px;
              margin-left: 0px;
            }
            .Lnea-148 {
              margin-top: 5px;
              width: 100%;
            }
          }
          .rectangle-priorization {
            width: 80%;
            font-size: 10px;
            margin-left: 50px;
            .Rectngulo-420,
            .Rectngulo-421 {
              width: 144px;
              height: 20px;
              span {
                font-size: 10px;
              }
              .Elipse-152 {
                margin: -14px 0 5px 111.8px;
              }
            }
          }
        }
      }
    }
  }
  // CARD
  .card-detail-priorization {
    margin-left: 50% !important;
    transform: translateX(-50%);
    padding-left: 0px !important;
    .row {
      .card-detail-content-front {
        margin-bottom: 20px;
      }
    }
  }
  // COMMENT BOX
  .Rectngulo-406 {
    .row {
      .Like:nth-child(1) {
        margin-left: -30% !important;
      }
      .Like:nth-child(2) {
        margin-left: 50% !important;
        margin-top: -32px;
      }
      .Like {
        width: 100%;
        padding: 0;
      }
    }
  }
  textarea#comment {
    width: 100%;
  }
  .coomentbox-comment {
    width: 100% !important;
  }
  // PRIORIZATION DETAIL
  .modal-content {
    margin: 50px 0;
  }
  .priorization-detail-card {
    width: 60%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .modal-footer_priorization {
    font-size: 12px;
  }
  .modal-body .card-detail-content-front {
    width: 100%;
    margin-bottom: 30px;
     
  }
  .col-xl-3.col-lg-3.col-md-3.col-sm-3.col-12.priorization-rating {
    width: 50%;
    font-size: 12px;
    padding-left: 40px;
  }
  .implementation-title-priorization {
    margin-top: 18px !important;
  }
  .impacto-title-priorization {
    margin-top: -5px !important;
  }
  // ** Home Page
  .FLASHCARDS-CLIMB {
    font-size: 45px;
  }
  .FC_Mocukup_LandingPage {
    margin: 0;
    padding: 40px 60px 32px 34px;
    height: auto;
    .col-5 {
      width: 100%;
    }
    img.home-banner-front-icon {
      margin-top: 30px;
      margin-left: 35% !important;
    }
  }

  .home-subtitle {
    font-size: 18px;
  }
  .home-page-circles {
    .col-6 {
      width: 100%;
      margin-left: 5%;
    }
  }
  .home-elipse,
  .home-elipse-light {
    width: 150px;
    height: 150px;
    padding: 65px 19px !important;
    line-height: 2px;
    margin-top: 80px;
    span {
      font-size: 12px;
      margin-top: 13%;
      width: 110px;
    }
    .mapeo-circle {
      margin-top: -20px;
      padding: 0 10px;
    }
    .priorizacion-circle {
      margin-top: -10px;
    }
    .implementacion-circle {
      margin-top: -10px;
      margin-left: 10px;
    }
    .home-text-circle2 {
      margin-left: 30px;
    }
  }
  .home-label-h1 {
    font-size: 45px;
    padding: 0;
    float: none;
  }
  // Matriz de acción
  .home-page-matrix {
    .row {
      display: flex;
      flex-direction: column;
      img.home-img-eje {
        float: none;
        margin-left: 100% !important;
        margin-bottom: 50px;
      }
      .col-5 {
        width: 100%;
        padding: 0 50px;
        .title-h1 {
          margin: 0;
        }
        .text-h1 {
          width: 100%;
          font-size: 18px;
        }
        .text-h1-2 {
          margin-top: 100px !important;
        }
      }
    }
  }
  // CAROUSEL
  .Conoce-las-flashcards-con-mayor-ranking {
    margin-left: -5px;
  }
  .cards__slider-rows {
    padding: 0 10px;
    margin-left: -8px;
  }
  // GTA HEADER
  .gta__header {
    padding-top: 0;
    h1 {
      font-size: 20px;
    }
    img {
      width: 120px;
      margin-top: 50px;
      margin-left: 30% !important;
    }
    .col-lg-4.logo {
      width: 50vw;
    }
    .col-lg-8.title {
      width: 100vw;
      h1 {
        margin-left: 50%;
        margin-top: -30px;
      }
      .gta__text {
        padding: 0 30px;
        p {
          max-width: 90vw;
          margin-left: -10% !important;
          font-size: 14px;
          line-height: normal;
          margin-top: 50px;
        }
      }
    }
    .gta__description {
      width: 100vw;
      padding: 0 40px;
      margin-top: -90px;
      h3 {
        font-size: 20px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  // GTA PAGE
  .gta__page {
    overflow-x: hidden;
  }
  // GTA FRONT CARD
  .gta__containerCards {
    padding: 0 0 0 7%;
  }
  .gta__card-container {
    margin-bottom: -100px;
    .gta__front,
    .gta__back {
      height: 220px;
    }
  }
  // GTA DETAIL CARD BACK
  .gta__mainCard {
    h4 {
      font-size: 12px;
    }
  }
  .back-detail {
    h3 {
      font-size: 10px;
      margin-top: -10px;
    }
  }
  .card-org-detail {
    h2 {
      font-size: 12px;
    }
  }
  // GLOSSARY HEADER
  .glossaryPage {
    .ranking-contain {
      display: flex;
      img {
        position: unset !important;
        width: 100px !important;
        margin-left: 0 !important;
        margin-top: -30px !important;
      }
      h2 {
        font-size: 30px;
        margin-left: 60px !important;
      }
    }
    .glossary-left_description {
      position: unset;
    }
  }
  // GLOSSARY CONTAIN
  .glossary-grid {
    margin-bottom: 70px;
    .glossary-contain {
      width: 100%;
      .row {
        margin-left: 0;
        .glossary-left_description {
          margin-left: 50%;
          transform: translateX(-50%);
          width: 100%;
          padding-left: 40px;
        }
      }
    }
  }
  // MAPA MENTAL GLOSARIO
  .glossary_map {
    img {
      margin-left: 0 !important;
    }
  }
  .glossary-workaxis {
    padding: 0;
  }
  .glossary-general_description {
    padding: 0;
  }
  .glossary-workaxis .glossary-img_title {
    margin: 0px 3px;
  }
  .glossary-line {
    margin-left: 52%;
  }
  // ** Ranking page
  .ranking-page {
    .ranking-grid {
      margin: 0;
    }
    .cards__slider-rows {
      margin-left: 10px;
    }
    form.form.ranking-form {
      margin-left: 15px;
      margin-top: -20px;
    }
    .ranking-title {
      font-size: 40px;
      text-align: center;
    }
    .ranking-subtitle {
      font-size: 16px;
    }
  }
  // ** Solution map Page
  .solution-map-page {
    form {
      .row {
        flex-direction: column;
        .custom-select-filter {
          width: 80%;
        }
      }
    }
    .cards__card-dimension {
      margin-left: 50%;
      transform: translateX(-65%);
    }
  }
  // ** Profile page
  .profile-edit-form {
    overflow-x: hidden;
  }
  .profile_page {
    overflow-x: hidden;
  }
  .user__profile-description {
    margin-left: 18% !important;
    h2 {
      margin-top: 8px !important;
    }
  }
  .user__profile-details {
    margin-left: 15px;
    .profile__form {
      margin-left: 6px;
      .profile__icons__edit {
        left: 18px;
      }
    }
    .row {
      .col-6 {
        margin-left: 25%;
        width: 100%;
        input {
          margin-left: 0;
          width: 100%;
        }
      }
    }
    .user-profile-div-button {
      margin-left: 25%;
      transform: translateX(-50%);
    }
  }
  .profile__form .profile__form .select-input__profile,
  .profile__form .profile__form .css-b62m3t-container {
    margin-left: 0;
    width: 100%;
  }
  .user__pfl,
  .user__profile {
    .user__profile-detailss {
      margin-top: 0;
      margin-left: 3.4px;
    }
    .user__profile-header {
      margin-top: 10px;
    }
    .profile__form {
      width: 100vw;
      .row {
        .col-6 {
          width: 100%;
          input {
            margin-left: 24px;
            width: 80%;
            margin-bottom: 20px;
            margin-top: -50px !important;
          }
          .profile__icons {
            left: 50px;
            top: 20px;
          }
          label {
            margin-left: 24px;
          }
          .user-profile-div-button {
            margin-left: 50%;
            transform: translateX(-50%);
            button {
              margin-right: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  // SAVED CARDS
  .profile_saved_cards {
    margin-left: 6px;
    form {
      .row {
        flex-direction: column;
        .col {
          margin-left: 3%;
        }
        .custom-select-filter {
          width: 80%;
        }
      }
    }
    .cards__card-dimension {
      margin-left: 56%;
      transform: translateX(-65%);
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  *:focus {
    outline: none;
  }
  // ** Nav && menuFullPage

  #root {
    overflow-x: hidden;
  }

  .row-layout-light {
    background-color: #fff;
    margin-right: 0;
    margin-left: 0;
  }

  .nav-grid {
    display: none;
  }
  .navbar-nav {
    overflow: hidden;
  }
  .navLight-agenda {
    padding: 10px 35px;
    height: 50px;
  }
  .navbar .dropdown-menu .dropdown-item:hover {
    background: transparent;
    color: #c0d4e8;
  }

  .navbar .dropdown-menu .dropdown-item {
    padding: 0px 0px;
    color: #fff;
    transition: none;
  }

  .bm-cross-button a {
    color: #000;
    left: 0px;
    top: 10px;
  }
  .full-page-menu {
    margin-left: 10% !important;
  }
  .fullpage-item {
    font-size: 26px !important;
  }
  .bm-menu .bm-item-list a {
    font-size: 26px;
  }
  .navbar-brand {
    position: absolute;
    top: 10px;
    left: 50px;
  }
  // end
  // ** Footer
  .logo-mobile {
    display: flex;
    justify-content: center;
  }
  .footer-img {
    margin: 0;
    width: 35%;
    height: 50%;
  }
  .footer-title {
    display: none;
  }
  .contact-mobile {
    display: none;
  }
  .footer-location-square {
    display: none;
  }
  .logopact-mobile {
    display: flex;
    justify-content: center;
  }
  .footer-logo {
    width: 30%;
    margin-left: 0;
    position: relative;
    left: 0;
  }
  .footer-logo-stk {
    width: 10%;
    display: flex;
    margin: auto !important;
    margin-top: 2rem !important;
  }
  .footer-border {
    border-top: none;
  }

  .footer-legal {
    justify-content: space-evenly;
  }

  // end
  // ** Contact Page

  .user__contact-container {
    display: none;
  }
  .user__contact-form {
    width: 105%;
    padding: 2rem 4rem 0 1rem;
  }
  .contact__form h3 {
    margin-left: 0.8rem;
  }
  .contact__form .contact__form-info input {
    margin: 1rem;
    width: 106%;
  }
  .contact__form .contact__form-subject input {
    margin: 1rem;
    width: 213%;
  }
  .contact__form textarea {
    margin: 1rem;
    width: 213%;
  }
  .contact__form label {
    margin-left: 17px;
  }
  .contact__form button {
    width: 100%;
    margin: 2rem;
  }
  // end
  // ** Page register

  .register-mobile {
    display: none;
  }
  .users__main {
    padding: 15px 0 150px 0;
    margin-bottom: 100px;
  }
  .users__box-container {
    width: 86%;
  }
  .sector__company-row {
    margin: 11px -25px -25px -25px;
    border-radius: 0px 0px 10px 10px;
  }
  .users__main .radio-button-gender {
    margin-right: 14px;
  }
  // end
  // ** Page Login && recover

  .auth__main {
    padding: 0px 0 30px 0 !important;
    margin-left: -32px !important;
    width: 100% !important;
  }
  .login__form {
    margin-top: -43px !important;
  }
  .auth__form {
    margin-left: 0px !important;
  }

  .login__form .login__input {
    margin-top: 0px !important;
  }
  // end
  // ** Priorization Page
  .header-img {
    width: 60%;
    margin-left: 8rem !important;
    margin-bottom: 2rem;
  }
  .header-grid {
    padding: 1rem 4rem 0rem 4rem;
  }
  .result-title {
    margin: 3rem 4.3rem 0;
  }
  .cards__row {
    padding: 0 5rem;
  }

  .search-mobile {
    display: flex;
    justify-content: flex-end;
    margin-left: 55px;
  }
  .cards-mobile {
    // margin: 0 2.8rem;
    justify-content: center;
    margin-left: 0;
    .solution-map-page .cards__card-dimension {
      margin-left: 0;
    }
  }
  // ** Implementation page
  .headerImpl-color {
    padding: 2rem 0rem 2rem 3rem;
  }
  .headerImpl-grid {
    padding: 0rem 7rem 0rem 3rem;
  }
  // PAGE
  .priorization-page-detail {
    overflow-x: hidden;
  }
  // HEADER CARD DETAIL
  .card__detail-container {
    .row {
      flex-direction: column;
      .col-12 {
        display: flex;
        margin: 30px 0;
        margin-left: 11%;
        .Rectngulo-141 {
          width: 100%;
          padding: 0;
          .Contratar-externosas-para-evaluar-los-procesos-de-reclutamiento-con-perspectiva-de-gnero {
            width: 100%;
          }
        }
      }
      .card-detail-number,
      .Contratar-externosas-para-evaluar-los-procesos-de-reclutamiento-con-perspectiva-de-gnero {
        margin-left: 30px !important;
      }
      .col-3 {
        width: 100%;
        .Rectngulo-141 {
          display: flex;
          width: 100%;
          padding: 0 30px;
          .rectangle-card-detail {
            width: 25%;
            img {
              width: 100px;
              margin-top: -15px;
              margin-left: 70% !important;
            }
            .Diversidad-e-Inclusin-Igualdad-de-gnero {
              font-size: 12px;
              width: 100%;
              margin-top: 0px;
              margin-left: 70%;
            }
            .Lnea-148 {
              margin-top: 5px;
              margin-left: 70%;
              width: 100%;
            }
          }
          .rectangle-priorization {
            width: 80%;
            font-size: 14px;
            margin-left: 45%;
            .Rectngulo-420,
            .Rectngulo-421 {
              width: 154px;
              height: 25px;
              span {
                font-size: 14px;
              }
              .Elipse-152 {
                margin: -14px 0 5px 111.8px;
              }
            }
          }
        }
      }
    }
  }
  // PRIORIZATION DETAIL MODAL
  .modal-content {
    margin: 50px 0;
  }
  .modal-body {
    .priorization-detail-card {
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
  .modal-body .card-detail-content-front {
    width: 100%;
    margin-bottom: 30px;
     
  }
  .col-xl-3.col-lg-3.col-md-3.col-sm-3.col-12.priorization-rating {
    width: 50%;
    font-size: 12px;
    padding-left: 100px;
  }
  .implementation-title-priorization {
    margin-top: 18px !important;
  }
  .impacto-title-priorization {
    margin-top: -5px !important;
  }
  // CARD
  .card-detail-priorization {
    margin-left: 27% !important;
    padding-left: 0px !important;
    width: 100%;
    .row {
      flex-direction: row;
      width: 98%;
      .card-detail-content-front {
        margin-bottom: 20px;
      }
    }
  }
  // COMMENT BOX
  .Rectngulo-406 {
    width: 90%;
    margin-left: -18% !important;
    .row {
      .Like:nth-child(1) {
        margin-left: -5% !important;
      }
      .Like:nth-child(2) {
        margin-left: 79% !important;
        margin-top: -32px;
      }
      .Like {
        width: 100%;
        padding: 0;
      }
      .form-group.mt-2 {
        width: 100%;
      }
    }
  }
  textarea#comment {
    width: 100%;
  }
  .coomentbox-comment {
    width: 100% !important;
  }
  .commentbox-container {
    width: 100%;
  }
  // ** Home Page
  .FC_Mocukup_LandingPage {
    margin: 0;
    padding: 50px 50px;
    height: auto;
    .col-5 {
      width: 100%;
    }
    img.home-banner-front-icon {
      margin-top: 30px;
      margin-left: 26% !important;
    }
  }
  .FLASHCARDS-CLIMB {
    font-size: 50px;
  }
  .home-page-circles {
    .col-6 {
      width: 100%;
      margin-left: 17%;
    }
  }
  .home-page {
    .row-layout-light {
      .container {
        padding-bottom: 0 !important;
      }
    }
  }
  .Conoce-las-flashcards-con-mayor-ranking {
    margin: 1rem;
  }
  .home-card-slider {
    .cards__slider-rows {
      margin-bottom: 0 !important;
    }
  }
  .flashcard-container,
  .flashcard-front,
  .flashcard-back {
    width: 70%;
  }
  // HOME CIRCLES
  .home-label-h1 {
    font-size: 45px;
    padding: 0;
    float: none;
  }
  .home-main {
    // margin-left: -5%;
  }
  .home-elipse,
  .home-elipse-light {
    width: 220px;
    height: 220px;
    padding: 65px 19px !important;
    line-height: 2px;
    margin-top: 80px;
    span {
      font-size: 20px;
      margin-top: 13%;
      width: 110px;
    }
    .mapeo-circle {
      margin-top: -10px;
      padding: 0 10px;
    }
    .priorizacion-circle {
      margin-top: 10px;
    }
    .implementacion-circle {
      margin-top: 10px;
      margin-left: 10px;
    }
    .home-text-circle2 {
      margin-left: 30px;
    }
  }
  // Matriz de acción
  .home-page-matrix {
    .row {
      display: flex;
      flex-direction: column;
      img.home-img-eje {
        float: none;
        margin-left: 150% !important;
        margin-bottom: 50px;
      }
      .col-5 {
        width: 100%;
        padding: 0 50px;
        .title-h1 {
          margin: 0;
        }
        .text-h1 {
          width: 100%;
        }
        .text-h1-2 {
          margin-top: 100px !important;
        }
      }
    }
  }
  // GTA HEADER

  .gta__mainCard .row h4 {
    width: 75%;
    margin-left: 95px;
  }
  .gta__header {
    padding-top: 0;
    h1 {
      font-size: 20px;
    }
    img {
      width: 120px;
      margin-top: 50px;
    }
    .col-lg-4.logo {
      width: 50vw;
    }
    .col-lg-8.title {
      width: 100vw;
      h1 {
        margin-left: 55%;
        margin-top: -30px;
      }
      .gta__text {
        padding: 0 30px;
        p {
          max-width: 90vw;
          margin-left: 5% !important;
          font-size: 14px;
          line-height: normal;
          margin-top: 50px;
        }
      }
    }
    .gta__description {
      width: 100vw;
      padding: 0 40px;
      margin-top: -90px;
      .col-lg-6 {
        width: 75vw;
        margin-left: 5% !important;
      }
      h3 {
        font-size: 20px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  // GTA PAGE
  .gta__page {
    overflow-x: hidden;
  }
  // GTA FRONT CARD
  .gta__containerCards {
    padding: 0 0 0 4%;
  }
  .gta__card-container {
    margin-bottom: -100px;
    .card-position {
      font-size: 8px !important;
    }
    .gta__front,
    .gta__back {
      height: 220px;
    }
  }
  // GTA DETAIL CARD BACK
  .gta__mainCard {
    h4 {
      font-size: 12px;
    }
  }
  .back-detail {
    h3 {
      font-size: 10px;
      margin-top: -10px;
    }
  }
  .card-org-detail {
    h2 {
      font-size: 12px;
    }
  }
  // GLOSSARY HEADER
  .glossaryPage {
    overflow-x: hidden;
    .ranking-contain {
      display: flex;
      img {
        position: unset !important;
        width: 160px !important;
        margin-left: 0 !important;
        margin-top: -30px !important;
      }
      h2 {
        font-size: 50px;
        margin-left: 60px !important;
      }
    }
    .glossary-left_description {
      position: unset;
    }
    .glossary-workaxis {
      padding: 0;
      margin-left: 28%;
      transform: translateX(-50%);
    }
  }
  // GLOSSARY CONTAIN
  .glossary-grid {
    margin-bottom: 80px !important;
    .glossary-contain {
      width: 80%;
      .row {
        margin-left: 0;
        .glossary-left_description {
          margin-left: 41%;
          transform: translateX(-50%);
          width: 100%;
          padding-left: 40px;
        }
      }
    }
  }
  .glossary-general_description {
    flex-direction: column;
    margin-left: 45%;
    transform: translateX(-50%);
    padding: 0;
    .col-md-4 {
      width: 100%;
    }
  }
  // MAPA MENTAL GLOSARIO
  .glossary_map {
    img {
      margin-left: 38% !important;
      transform: translateX(-50%);
    }
  }
  .glossary-description {
    width: 500px !important;
  }

  // ** Ranking Page
  .ranking-page {
    .cards__slider-rows {
      margin-left: 10px;
    }
    form.form.ranking-form {
      margin-left: 15px;
      margin-top: -20px;
    }
    .ranking-title {
      font-size: 50px;
      text-align: center;
    }
    .ranking-subtitle {
      font-size: 25px;
    }
  }
  .solution-map-page {
    form {
      .row {
        flex-direction: column;
        margin-left: 16%;
        .custom-select-filter {
          width: 80%;
        }
      }
    }
  }

  .profile-edit-form {
    overflow-x: hidden;
  }
  .profile_page {
    overflow-x: hidden;
  }
  .user__profile-description {
    margin-left: 18% !important;
    h2 {
      margin-top: 8px !important;
    }
  }
  .user__profile-details {
    margin-left: 15px;
    .profile__form {
      margin-left: 6px;
      .profile__icons__edit {
        left: 18px;
      }
    }
    .row {
      .col-6 {
        margin-left: 23%;
        width: 100%;
        input {
          margin-left: 0;
          width: 50vw;
        }
      }
    }
    .user-profile-div-button {
      margin-left: 30%;
      transform: translateX(-50%);
    }
  }
  .profile__form .profile__form .select-input__profile,
  .profile__form .profile__form .css-b62m3t-container {
    margin-left: 0;
    width: 50vw;
  }
  .user__pfl,
  .user__profile {
    .user__profile-detailss {
      margin-top: 0;
      margin-left: 3.4px;
    }
    .user__profile-header {
      margin-top: 20px;
      margin-bottom: -15px;
    }
    .profile__form {
      width: 100vw;
      .row {
        .col-6 {
          width: 100%;
          input {
            margin-left: 40px;
            width: 80%;
            margin-bottom: 20px;
            margin-top: -50px !important;
          }
          .profile__icons {
            left: 60px;
            top: 20px;
          }
          label {
            margin-left: 40px;
          }
          .user-profile-div-button {
            margin-left: 50%;
            transform: translateX(-50%);
            button {
              margin-right: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  // SAVED CARDS
  .profile_saved_cards {
    margin-left: 6px;
    form {
      .row {
        flex-direction: column;
        .col {
          margin-left: 3%;
        }
        .custom-select-filter {
          width: 90%;
        }
      }
    }
    .cards__row {
      height: 75vh;
    }
  }
  .user__pfl {
    height: 15%;
  }
}

// Medium devices (tablets, 768px and up) 2
@media (min-width: 992px) and (max-width: 1199.98px) {
  .glossary-left_description {
    width: 45%;
  }
  .glossary-line {
    margin-top: 70px;
  }
  .glossary-workaxis {
    margin-left: 20%;
  }
  /********************************/
  /*****SOLUTION MAP CARDS********/
  /********************************/
  .cards__row {
    padding: 0;
    padding-left: 6px;
  }
  /********************************/
  /*****PRIORIZATION DETAIL********/
  /********************************/
  .card-detail-priorization {
    width: 100%;
    .row {
      justify-content: center;
    }
  }
  .Rectngulo-141 {
    display: flex;
    justify-content: center;
    .card-detail-number {
      margin-left: 90px;
    }
  }
  // MODAL PRIORIZATION DETAIL

  // GTA ICON
  .gta__img-circle {
    margin-top: 21%;
  }
}

// Medium devices (tablets, 768px and up) 3
@media (min-width: 1800px) {
  // ** Footer
  .gta__img-circle {
    margin-top: 9%;
  }
  .footer-logo {
    // position: absolute;
    left: 45rem;
  }
  .footer-logo-stk {
    // position: absolute;
    left: 45rem;
  }
  // SAVED CARDS
  .glossary-left_description {
    width: 45%;
  }
  .glossary-line {
    width: 93.5%;
    margin-top: 70px;
  }
  .glossary-workaxis {
    margin-left: 20%;
    /********************************/
    /*****SOLUTION MAP CARDS********/
    /********************************/
    .cards__row {
      padding: 0;
      padding-left: 6px;
    }
    /********************************/
    /*****PRIORIZATION DETAIL********/
    /********************************/
    .card-detail-priorization {
      width: 100%;
      .row {
        justify-content: center;
      }
    }
    .Rectngulo-141 {
      display: flex;
      justify-content: center;
      .card-detail-number {
        margin-left: 90px;
      }
    }
    // MODAL PRIORIZATION DETAIL
    .card-detail-actions {
      position: absolute !important;
      top: 60% !important;
      left: 44% !important;
    }
    // COMMENT BOX
    .Rectngulo-406 {
      margin-top: 15%;
    }
  }
  .mess-full {
    justify-content: center;
    margin-left: -6.5rem;
  }

  .card-full {
    justify-content: center;
    margin: 1rem;
    margin-left: -5.5rem;
  }

  .card-detail-actions {
    width: 0px;
    display: inline-flex;
    // padding: 0px;
    // margin: 0px;
    vertical-align: middle;
    float: left;
    // height: 300px;
    // padding-top: 215px;
    // position: absolute;
    // right: 22rem;
  }
}

.cards-map-height {
}

@media (max-width: 991px) {
  .gta__text {
    margin: 41px 17px 0 13px;
    width: 100%;
    display: flex;
  }

  .gta__header h1 {
    margin: 81px 17px -20px 13px;
    font-size: 35px;
  }

  .gta__header h3 {
    margin: 31px 17px -20px 13px;
    padding-left: 13px;
    font-size: 33px;
  }
  img.gta__img-circle {
    height: 162px;
  }

  .back-detail {
    margin: 10px 0 5px 0 !important;
  }
  .row.back-detail {
    margin-top: 9px !important;
    line-height: 10px;
  }
  img.img-icon.img-fluid {
    height: 43px;
    display: none;
  }
  .gta-name-dir {
    font-size: 12px !important;
  }
  .card-org-detail {
    padding: 6px 70px 0 25vw !important;
    // margin: 13px 0 0 138px;
    text-align: right;
    // width: 295px;
    font-size: 9px !important;
    line-height: 13px;
  }
  .card-position {
    font-size: 11px !important;
  }
  .gta__text-h4-subttitle {
    margin: 60px 0 0 -39px !important;
    position: absolute;
    text-align: right;
  }
  p.card-position {
    font-size: 10px !important;
    line-height: 9px;
  }
  .gta__card-container {
    margin-left: 15px;
  }
  .gta-name-ceo {
    font-size: 14px !important;
    margin: 8px 0px 0px 0px;
    padding-bottom: 3px;
  }
  .gta-name-dir {
    font-size: 12px !important;
    margin: 8px 0px 7px 0px;
    padding-bottom: 3px;
  }
  .gta-name-ceo2 {
    font-size: 14px !important;
    margin: 8px 0px 4px 0px;
    padding-top: 4px;
  }
  .gta__header img {
    width: 210px;
    margin-top: 50px;
    margin-left: 12% !important;
  }
  .gta__text-pp {
    padding-left: 38px;
  }
  .gta-conformation {
    padding-left: 0px !important;
  }

  .gta__list-cards.container {
    margin-top: 0px;
  }
  .gta__list-cards {
    padding: 0 2rem;
  }
  p.gta__text-pp {
    line-height: 22px !important;
  }
  .gta__mainCard {
    padding-left: 0px !important;
    margin-left: 14px !important;
  }
  .modal-content {
    width: calc(100vw - 30px);
    justify-content: center;
    padding: 0 0 0 8px;
  }

  .join-col-8 {
    margin-right: 129px;
    min-width: 100%;
  }
  h3.join-detail-title-popup {
    font-size: 25px;
  }
  h3.join-more-email-modal {
    font-size: 18px;
  }
}
.img-modal-detail-join {
  width: 150px !important;
  margin: 3px 0 36px 0px !important;
}

.gta__header h3 {
  margin: 31px 17px 10px 0px;
  font-size: 33px;
}
