.back__header {
  // height: 95vh;
  // max-height: 700px;
  height: calc(100vh - 266px);
  min-height: 700px;
  background-image: url('../../assets/img/ODS.webp');
  background-size: cover;

  @media (max-width: 490px) {
    max-height: 1100px;
  }
}
.gta-p-ceo2-text {
  @media (max-width: 490px) {
    width: 250px;
    max-height: 80px;
    overflow: hidden;
  }
}
.gta__headers {
  h1 {
    color: #709ec9;
    font-size: 47px;
  }
  p {
    font-size: 25px;
    width: 80%;
  }
  h4 {
    color: #709ec9;
    font-weight: bold;
    font-size: 25px;
    text-align: left;
    margin-top: 2rem;
  }
  li {
    font-size: 23px;
    text-align: left;
    color: #f0f0f0;
    margin-bottom: 1.8rem;
  }
  .p-gta {
    overflow: hidden !important;
    max-height: 167px;
  }
  .gta__text-pp {
    font-size: 23px;
  }

  .gta__description {
    h3 {
      font-size: 15pt;
    }
    li {
      list-style: disc;
      font-size: 15pt;
      margin-bottom: 0;
    }
    span {
      font-size: 15pt;
    }
  }
  h3 {
    color: #709ec9;
    font-weight: bold;
    font-size: 18px;
  }

  ul li {
    list-style: none;
  }
  hr {
    width: 108%;
    margin-left: 50%;
    transform: translateX(-50%);
    height: 3px;
    background-color: #709ec9;
    opacity: 1;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    .home-main-ods {
      display: table-caption;
      margin: 1rem 2rem;
    }
    .back__header {
      height: 130vh;
    }
  }
}

.gta__header {
  h1 {
    color: #709ec9;
    font-size: 47px;
  }
  p {
    font-size: 25px;
    width: 80%;
  }
  h4 {
    color: #709ec9;
    font-weight: bold;
    font-size: 25px;
    text-align: left;
    margin-top: 2rem;
  }
  li {
    font-size: 23px;
    text-align: left;
    color: #f0f0f0;
    margin-bottom: 1.8rem;
  }
  .p-gta {
    overflow: hidden !important;
    max-height: 167px;
  }
  .gta__text-pp {
    font-size: 23px;
  }

  .gta__description {
    h3 {
      font-size: 15pt;
    }
    li {
      list-style: disc;
      font-size: 15pt;
      margin-bottom: 0;
    }
    span {
      font-size: 15pt;
    }
  }
  h3 {
    color: #709ec9;
    font-weight: bold;
    font-size: 18px;
  }

  ul li {
    list-style: none;
  }
  hr {
    width: 108%;
    margin-left: 50%;
    transform: translateX(-50%);
    height: 3px;
    background-color: #709ec9;
    opacity: 1;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.gta__list {
  margin: 2rem 0rem;
}

/********************************************
CARDS
********************************************/
.gta__containerCards {
  margin: 0;
  padding: 0 4% 0 6%;
  padding-top: 68px;
}
/* entire container, keeps perspective */
.gta__card-container {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  margin-bottom: 30px;
}
/* flip the pane when hovered */
.gta__card-container:not(.manual-flip):hover .card,
.gta__card-container.hover.manual-flip .gta__card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.gta__card-container.static:hover .gta__card,
.gta__card-container.static.hover .gta__card {
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
}

.gta__card-container,
.gta__front,
.gta__back {
  width: 100%;
  height: 360px;
  border-radius: 4px;
  padding: 3px;
}

/* flip speed goes here */
.gta__card {
  border: none;
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;
}

.gta__card .cover {
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.gta__card .cover img {
  width: 100%;
}

.gta__card .content {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  padding: 10px 20px 20px;
}
.gta__card .content .mainCard {
  min-height: 160px;
  vertical-align: middle;
  padding: 5px;
}
.gta__card .cover img {
  width: 100%;
}

.gta__card .content {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  padding: 10px 20px 20px;
}
.gta__card .content .mainCard {
  min-height: 160px;
  vertical-align: middle;
  padding: 5px;
}

.gta__mainCard .row {
  padding: 8px;
  margin-top: -15px !important;
}
.gta__card .gta__back .content .gta__mainCard {
  height: 215px;
}
.gta__card .name {
  font-size: 19px;
  line-height: 28px;
  padding: 0 8px;
  margin: 10px 0 0;
  text-align: center;
  margin-top: -23px;
  font-weight: 500;
}
.gta__card h5 {
  margin: 5px 0;
  font-weight: 400;
  line-height: 20px;
}
.gta__card .profession {
  color: #999999;
  text-align: center;
  margin-bottom: 20px;
}
.gta__card .footer {
  border-top: 1px solid #f0f0f0;
  color: #999999;
  margin: 30px 0 0;
  padding: 10px 0 0;
  text-align: center;
}
.gta__card .footer .social-links {
  font-size: 18px;
}
.gta__card .footer .social-links a {
  margin: 0 7px;
}

.gta__card .footer .btn-simple {
  margin-top: -6px;
}
.gta__card .header {
  padding: 15px 20px;
  height: 90px;
}
.gta__card .motto {
  border-bottom: 1px solid #eeeeee;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center;
}

.gta__card .stats-container {
  width: 100%;
  margin-top: 50px;
}
.gta__card .stats {
  display: block;
  float: left;
  width: 33.333333%;
  text-align: center;
}

.gta__card .stats:first-child {
  border-right: 1px solid #eeeeee;
}
.gta__card .stats:last-child {
  border-left: 1px solid #eeeeee;
}
.gta__card .stats h4 {
  font-weight: 300;
  margin-bottom: 5px;
}
.gta__card .stats p {
  color: #777777;
}

/* hide back of pane during swap */
.gta__front,
.gta__back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 1px 14px 5px rgba(0, 0, 0, 0.021);
}

/* front pane, placed above back */
.gta__front {
  z-index: 2;
}

.gta__img-circle {
  position: relative;
  width: 40%;
  height: auto;
  max-height: 289px;
  // margin: auto;
  margin-left: 50% !important;
  transform: translateX(-50%);
  margin-top: 25px;
  z-index: 1;
}

/* back, initially hidden pane */
.gta__back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 3;
}

.gta__back .btn-simple {
  position: absolute;
  left: 0;
  bottom: 4px;
}

.gta__mainCard {
  padding: 8px;
}

.gta__mainCard h4 {
  text-transform: uppercase;
  margin-left: 120px;
  margin-top: -50px !important;

  @media (max-width: 575.98px) {
    font-size: 12px;
    padding-left: 12px;
    line-height: 12px;
    padding-right: 16px;
  }
}
.gta__mainCard .row h4 {
  width: 80%;
  margin-left: 95px;
}

.img-icon {
  width: 100px;
  margin-left: 0 !important;
  margin-top: 20px;
}

.logoGta {
  width: 25%;
}

.gta__card-container {
  .card {
    border: none !important;
  }
  h3 {
    font-size: 15px;
    color: #fff;
  }
  p {
    font-size: 10px;
    color: #fff;
  }
  .logo-org {
    width: 30px;
  }
  h4 {
    color: #fff;
  }
  h2 {
    color: #fff;
    font-size: 14px;
    margin-left: 30px;
    margin-top: 5px;
  }
  .card-position {
    font-size: 14px;
    margin-left: 30px !important;
    margin-top: -10px !important;
  }
}

.card-org-detail {
  position: absolute;
  bottom: 3px;
  margin-left: 45px;
  width: 100%;
}

.card-line {
  width: 1px;
  height: 100%;
  background: #fff;
}

.gta__back .gta__mainCard {
  margin-left: 20px;
  .back-detail {
    margin-left: 78px;
    margin-top: 10px;
  }
}

/* // Large devices (desktops, less than 1200px) */
@media (min-width: 1900px) and (max-width: 2000px) {
  .gta__img-circle {
    width: 38%;
    margin-top: 50px;
  }
  .gta__mainCard h4 {
    text-transform: uppercase;
  }
  .contain-title {
    padding: 0rem 0rem;
  }
  .gta__header hr {
    width: 95%;
  }
  .bm-menu .bm-item-list {
    padding: 16rem 0;
  }
}

.contain-title {
  @media screen and (max-width: 575.98px) {
    padding: 0rem 0rem 1rem 2rem;
  }
}

.gta__header .title p {
  color: #fff;
  text-align: left;
}
.gta__header .title p strong {
  font-weight: 600;
}

.gta__list-cards {
  max-width: 1320px;
  padding: 2rem 2rem 2rem 3rem;
}
