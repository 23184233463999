.auth__main {
  align-items: center;
  // background-color: $primary;
  display: flex;
  justify-content: center;
  margin: 0px;
  overflow: overlay;
  padding: 80px 0 50px 0;
  height: 100%;
  width: 100vw;
  color: #1e3250;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.auth__recover {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0px;
  overflow: overlay;
  padding: 80px 100px 50px 0;
  height: 100%;
  width: 100vw;
  color: #1e3250;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.auth__box-container {
  background-color: #fff;
  box-shadow: 3px 5px 20px 0px #3636361a;
  border-radius: 40px;
  padding: 25px 50px;
  width: 100%;
  max-width: 510px !important;
  max-height: 900px !important;
  // margin: 0 12%;
  margin-top: 6px;
  display: flex;
  .login__sideimg {
    border-radius: 0px 40px 40px 0px;
    position: relative;
    left: -10px;
  }
}

.auth__box-recover-container {
  background-color: #fff;
  box-shadow: 3px 5px 20px 0px #3636361a;
  border-radius: 40px;
  padding: 25px 0px;
  width: 100%;
  max-width: 510px !important;
  max-height: 900px !important;
  // margin: 0 12%;
  margin-top: 6px;
  display: flex;
  .login__sideimg {
    border-radius: 0px 40px 40px 0px;
    position: relative;
    left: -10px;
  }
  .recover__sideimg {
    border-radius: 0px 40px 40px 0px;
    position: relative;
    left: -60px;
    @media only screen and (max-width: 640px) {
      display: none;
    }
  }
  .recoverPwd__sideimg {
    border-radius: 0px 40px 40px 0px;
    width: 214px;
    object-fit: cover;
    position: relative;
    left: -60px;
  }
  .recoverPwd__icon {
    display: flex;
    width: 350px;
    span {
      color: #b5cee4;
      margin-left: -10px;
    }
    p {
      color: #1e3250;
      font-size: 14px;
      font-weight: bold;
      margin-left: 15px;
      margin-top: 2px;
    }
    .emaila {
      margin-top: 30px;
      margin-left: 0px;
      font-size: 18px;
      font-weight: 300;
      color: #1e3250;
      padding: 0 2px;
    }
  }
}

.auth__box-restore-container {
  background-color: #fff;
  box-shadow: 3px 5px 20px 0px #3636361a;
  border-radius: 40px;
  padding: 25px 0px;
  width: 100%;
  max-width: 510px !important;
  max-height: 900px !important;
  // margin: 0 12%;
  margin-top: 6px;
  display: flex;
  .login__sideimg {
    border-radius: 0px 40px 40px 0px;
    position: relative;
    left: -10px;
  }
  .recover__sideimg {
    border-radius: 0px 40px 40px 0px;
    position: relative;
    left: -60px;
    @media only screen and (max-width: 640px) {
      display: none;
    }
  }
  .recoverPwd__sideimg {
    border-radius: 0px 40px 40px 0px;
    width: 214px;
    object-fit: cover;
    position: relative;
    left: -60px;
  }
  .recoverPwd__icon {
    display: flex;
    width: 350px;
    span {
      color: #b5cee4;
      margin-left: -10px;
    }
    p {
      color: #1e3250;
      font-size: 14px;
      font-weight: bold;
      margin-left: 15px;
      margin-top: 2px;
    }
    .emaila {
      margin-top: 30px;
      margin-left: 0px;
      font-size: 18px;
      font-weight: 300;
      color: #1e3250;
      padding: 0 2px;
    }
  }
}

.recover__description {
  font-size: 17px;
  font-weight: 300;
  width: 300px;
}

.recoverPwd__input {
  input {
    width: 328px;
  }
}
.recover__form {
  margin-top: -25px;

  .recover__input:nth-child(2) {
    margin-left: -25px;
    @media only screen and (max-width: 640px) {
      margin-left: 0;
    }
  }

  .recover__input:nth-child(2) .recover__questioni {
    position: relative;
    width: 0px !important;
    top: 35px;
    left: 40px;
    color: #b5cee4;
    @media only screen and (max-width: 640px) {
      top: 68px;
      left: 15px;
    }
  }
  .recover__input {
    margin: 10px 0;

    input {
      height: 50px;
      color: #000;
      font-weight: 500;
      width: 323px;
      padding: 0 35px;
      background-color: transparent;
    }

    .error-message {
      font-size: 10px;
    }

    .recover__questioni {
      position: relative;
      top: 60px;
      left: 15px;
      color: #b5cee4;
      @media only screen and (max-width: 640px) {
        top: 68px;
        left: 15px;
      }
    }
  }
}

.recover__password {
  padding: 0px 50px;
  @media only screen and (max-width: 640px) {
    margin: 0 0 0 -4px;
    .mobile-btn{
      width: 75%;
      margin: 0 40px;
    }
  }
}

.auth__title {
  font-size: 24px;
  margin-bottom: 40px;
  text-align: center;
  @media only screen and (max-width: 640px) {
    margin: 30px 0px 30px -25px;
  }
}

.auth__botonesLogin {
  padding: 0px 33px;
  margin-top: -33px;
}

.login__social {
  display: flex;
  img {
    width: 8%;
    margin: 9px;
  }
  a {
    margin: 9px;
    background-color: #eaecef;
    border-radius: 24px;
    border-color: solid 1px #e0e0e0;
    width: 200px;
    height: 30px;
  }
  span {
    font-size: 9px;
    margin-left: 20px;
    color: #cccccc;
  }
}

.login__separator {
  display: flex;
  margin: 30px 9px;
  .login__line {
    width: 70px;
    height: 1px;
    background-color: #95a0a9;
  }

  span {
    width: 100px;
    font-size: 9px;
    line-height: 0;
    color: #ccc;
    margin: 0px 30px;
  }
}

.login__form {
  margin-top: -50px;
  .login__input:nth-child(2) {
    margin-left: -40px;
  }
  .login__input:nth-child(2) .login__questioni {
    position: relative;
    width: 0px !important;
    top: 35px;
    left: 40px;
    color: #b5cee4;
  }
  .login__input {
    margin-top: 0px;

    input {
      height: 50px;
      font-size: 14px;
      color: #000;
      font-weight: 500;
      width: 323px;
      padding: 0px 35px;
      background-color: transparent;
    }

    .error-message {
      font-size: 10px;
    }

    .login__icon {
      position: relative;
      top: 60px;
      left: 310px;
      color: #939393;
    }

    .login__questioni {
      position: relative;
      width: 0px !important;
      top: 60px;
      left: 15px;
      color: #b5cee4;
      @media only screen and (max-width: 640px) {
        top: 69px;
        left: 12px;
      }
    }
  }
}

.checkbox {
  label {
    display: flex;
    color: #6c757d;
    font-weight: normal;
    justify-content: center;
  }
  a {
    text-align: end;
    text-decoration: underline;
    font-weight: normal;
  }
}

.btn-primary {
  color: #fff;
  background: #709dc8;
  border-color: #709dc8;
  box-shadow: 3px 3px 8px -2px #ccc;
  transition: all 250ms linear !important;
  font-weight: 400;
  border-radius: 19px;
  &:hover {
    background: darken(#709dc8, 10%) !important;
    border-color: darken(#709dc8, 10%) !important;
  }
}

a {
  color: #414141;
  font-weight: 300;
  text-decoration: none;
  background-color: transparent;
}

.auth__paragraph {
  color: #52514f;
  font-size: 18px;
}

.input-base {
  display: flex;
  height: 52px;
  left: 718px;
  position: absolute;
  top: 328px;
  width: 526px;
}

.input-base-1 {
  background-color: var(--concrete);
  border-radius: 5px;
  flex: 1;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  width: 528.5px;
}

.collapse-button-collapse {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 723px;
  min-height: 59px;
  position: absolute;
  top: 848px;
  width: 1090px;
}

.button-default-primary {
  align-items: flex-end;
  background-color: #007bff;
  border-radius: 27px;
  display: flex;
  height: 59px;
  justify-content: flex-end;
  min-width: 1090px;
  padding: 6.5px 14px;
}

.text-2 {
  color: var(--white);
  font-family: var(--font-family-helveticaneue-regular);
  font-size: var(--font-size-xxl);
  letter-spacing: 0;
  line-height: 34px;
  min-height: 41px;
  min-width: 1060px;
  white-space: nowrap;
}

.collapse-accord-expand-content {
  align-items: flex-start;
  display: flex;
  height: 172px;
  justify-content: flex-end;
  margin-left: 74px;
  margin-top: 62px;
  min-width: 546px;
  opacity: 0;
}

.overlap-group {
  height: 170px;
  margin-top: -0.1px;
  position: relative;
  width: 546px;
}

.base {
  background-color: var(--white);
  border: 1px solid #dfdfdf;
  height: 127px;
  left: 0;
  position: absolute;
  top: 0;
  width: 371px;
}

.lorem-ipsum-dolor-si {
  color: #000000;
  font-family: var(--font-family-helveticaneue-regular);
  font-size: var(--font-size-m);
  height: 156px;
  left: 34px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 14px;
  width: 512px;
}

.crditos {
  align-items: flex-start;
  display: flex;
  height: 32px;
  left: 726px;
  min-width: 982px;
  position: absolute;
  top: 1008px;
}

.overlap-group3 {
  height: 32px;
  position: relative;
  width: 984px;
}

.componente-14-19 {
  display: flex;
  height: 32px;
  left: 486px;
  position: absolute;
  top: 0;
  width: 498px;
}

.text-3 {
  flex: 1;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: -6px;
  margin-right: -2px;
  margin-top: 4px;
  white-space: nowrap;
  width: 498px;
}

.componente-14-19.componente-14-19-1 {
  left: unset;
  position: unset;
  top: unset;
  width: 538px;
}

.componente-14-19.componente-14-19-1 .text-3 {
  width: 538px;
}

.componente-13-36 {
  display: flex;
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 493px;
}

.text-4 {
  -webkit-text-stroke: 1px var(--primario);
  flex: 1;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: -6px;
  margin-right: -2px;
  margin-top: 4px;
  white-space: nowrap;
  width: 493px;
}

.componente-13-36.componente-13-36-1 {
  left: unset;
  position: unset;
  top: unset;
  width: 143px;
}

.componente-13-36.componente-13-36-1 .text-4 {
  width: 143px;
}

.crditos-1 {
  align-items: flex-start;
  display: flex;
  height: 32px;
  left: 726px;
  min-width: 679px;
  position: absolute;
  top: 1041px;
}

.contacto {
  align-items: flex-start;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  width: 1920px;
}

.overlap-group4 {
  height: 1117px;
  position: relative;
  width: 1920px;
}

.rectngulo-187 {
  background-color: var(--concrete);
  height: 1117px;
  left: 0;
  position: absolute;
  top: 0;
  width: 631px;
}

.navbar-gta2030 {
  height: 1095px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 22px;
  width: 1920px;
}

.grupo-881 {
  align-items: flex-start;
  display: flex;
  height: 429px;
  left: 74px;
  min-width: 486px;
  position: absolute;
  top: 221px;
}

.flex-col {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 392px;
  width: 50px;
}

.imagen-34 {
  height: 53px;
  margin-right: 4px;
  object-fit: cover;
  width: 46px;
}

.imagen-35 {
  height: 46px;
  margin-right: 4px;
  margin-top: 178px;
  object-fit: cover;
  width: 46px;
}

.imagen-36 {
  height: 40px;
  margin-top: 75px;
  object-fit: cover;
  width: 43px;
}

.text-1 {
  align-self: flex-end;
  color: var(--primario);
  line-height: 30px;
  margin-bottom: -51.5px;
  margin-left: 6px;
  min-height: 431px;
  min-width: 430px;
  white-space: nowrap;
}

.contctanos {
  color: var(--primario);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxxl);
  font-weight: 400;
  left: 717px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 206px;
  white-space: nowrap;
}

.nombre {
  color: var(--primario);
  left: 717px;
  line-height: 30px;
  position: absolute;
  top: 288px;
  white-space: nowrap;
}

.overlap-group-1 {
  height: 92px;
  left: 1275px;
  position: absolute;
  top: 288px;
  width: 536px;
}

.input-base-3 {
  display: flex;
  height: 52px;
  left: 1px;
  position: absolute;
  top: 40px;
  width: 536px;
}

.input-base-4 {
  background-color: var(--concrete);
  border-radius: 5px;
  flex: 1;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  width: 537.6328125px;
}

.correo {
  color: var(--primario);
  left: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.overlap-group1 {
  height: 92px;
  left: 718px;
  position: absolute;
  top: 434px;
  width: 1094px;
}

.input-base-5 {
  display: flex;
  height: 52px;
  left: 1px;
  position: absolute;
  top: 40px;
  width: 1092px;
}

.input-base-2 {
  background-color: var(--concrete);
  border-radius: 5px;
  flex: 1;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  width: 1094.5px;
}

.asunto {
  color: var(--primario);
  left: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.overlap-group2 {
  height: 225px;
  left: 718px;
  position: absolute;
  top: 580px;
  width: 1094px;
}

.input-base-6 {
  display: flex;
  height: 184px;
  left: 1px;
  position: absolute;
  top: 40px;
  width: 1092px;
}

.mensaje {
  color: var(--primario);
  left: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');
@font-face {
  font-family: 'HelveticaNeue-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.animaapp.com/HelveticaNeue-Regular') format('truetype');
}
.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.animate__animated.animate__fadeIn.animate__faster.error-message {
  margin: -10px 0 5px 0;
  font-weight: 400;
  color: red;
}

.animate__animated.animate__fadeIn.animate__faster.error-message {
  font-size: 15px;
  margin: -10px 0 0px 0;
}

span.Ver-mis-tarjetas-guardadas {
  font-size: 13px;
}

.login_register-quest {
  p {
    margin-bottom: 2px;
  }

  a {
    color: #5a82ff;
    font-weight: bold;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
  }
}

// Logins social media

.social-media-bar{
  display: flex;
  justify-content: right;
  margin-top: 0px;
  width: 100%;
}

.social-media-logo-google{
  background-repeat: no-repeat;
  background-size: contain;
  height: 39px;
  width: 39px;
  cursor: pointer;
  margin: 10px;
  margin-bottom: 10px;

}

.social-media-bar-login{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}