@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

@font-face {
  font-family: 'Roboto';
  src: url('./../../assets/fonts/Flama.otf') format('opentype');
}

:root {
  --main-bg-color: #131a27;
  --bg-white: #ffffff;
  --bg-purple: #7367f0;
  --bg-tomato: #ea5455;
  --bg-blue: #0396ff;
  --bg-green: #28c76f;
  --bg-cyand: #32ccbc;
  --bg-yellow: #f8d800;
  --cyan-gradient: linear-gradient(135deg, #90f7ec 10%, #32ccbc 100%);
  --green-gradient: linear-gradient(135deg, #81fbb8 10%, #28c76f 100%);
  --blue-gradient: linear-gradient(135deg, #6b73ff 10%, #000dff 100%);
  --pruple-gradient: linear-gradient(135deg, #ce9ffc 10%, #7367f0 100%);
  --shadow-color: rgba(236, 50, 50, 0.08);
  --hover-shadow-color: rgba(0, 0, 0, 0.05);
}

* {
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 300;
}
.overflow-hide {
  overflow: hidden;
}
.overflow-show {
  overflow: auto;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid #aecfe6 !important;
  background-color: #aecfe6 !important;
}
.swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: #1e3250 !important;
  z-index: 99999 !important;
}
.swal2-container.swal2-center > .swal2-popup {
  border-radius: 21px !important;
}

body {
  overflow-x: hidden;
  background-color: var(--main-bg-color);
  color: #fff;
}

main > .container {
  padding: 60px 15px 0;
}

#landscape {
  display: none;
  z-index: -1;
}
@media (max-width: 390.98px) and (orientation: landscape) {
  #landscape {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f2;
    z-index: 999999999999;
    width: 100%;
    height: 100%;
    font-size: 120%;
    align-items: center;
    justify-content: center;
    p {
      color: #000;
      text-align: center;
      margin-top: 30px;
      font-weight: bold;
    }
    img {
      width: 50px;
      margin-left: 26px !important;
    }
  }
}
@media (max-width: 575.98px) and (orientation: landscape) {
  #landscape {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f2;
    z-index: 999999999999;
    width: 100%;
    height: 100%;
    font-size: 120%;
    align-items: center;
    justify-content: center;
    p {
      color: #000;
      text-align: center;
      margin-top: 30px;
      font-weight: bold;
    }
    img {
      width: 50px;
      margin-left: 26px !important;
    }
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  #landscape {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f2;
    z-index: 999999999999;
    width: 100%;
    height: 100%;
    font-size: 120%;
    align-items: center;
    justify-content: center;
    p {
      color: #000;
      text-align: center;
      margin-top: 30px;
      font-weight: bold;
    }
    img {
      width: 50px;
      margin-left: 26px !important;
    }
  }
}

.nav-agenda {
  background-color: #131a27;
  // padding-left: 30px;
  // padding-right: 20px;
}

.nav-agenda a {
  color: #fff;
}

.nav-agenda li {
  padding: 0 5px;
}

.nav-grid {
  margin: 0 5px;
  @media (min-width: 1800px) {
    margin: 0 40px;
  }
}

.logo-nav {
  width: 80%;
  @media (max-width: 575.98px) {
    position: relative;
    top: -17px;
    width: 70%;
    left: -70px;
  }
  // Medium devices (tablets, 768px and up) 2
  @media (min-width: 1800px) {
    width: 100%;
  }
}

.dropdown-agenda {
  top: 25px;
  left: 11px;
  @media (min-width: 1800px) {
    top: 30px;
  }
}

.dropdown-agenda a {
  color: #131a27;
}

.nav-link {
  font-weight: 500;

  &:focus {
    color: $general4;
    // background-color: $secundario2;
  }

  &:hover {
    // background-color: $secundario2;
    color: $general4;
  }
}

.nav-agenda > .active > a {
  color: #709ec9;
}
.active {
  font-weight: bold !important;
  color: #709ec9 !important;
}

.nav-item-languajes {
  display: flex;
}
.nav-item-profile {
  margin-left: -40px;
}
.nav-item-register {
  margin-left: 40px;
}

.dropdown-menu {
  background-color: $general3;
  font-weight: $primario;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: $general4;
  background-color: $secundario2;
}

.light,
.container-light {
  background-color: $general4;
  color: #000;
  @media only screen and (max-width: 640px) {
    width: 100%;
    padding: 10px 13px 10px 5px;
  }
}

.post-parraph {
  line-height: 1.25;
}

.post-paragraph {
  padding: 3px 8rem 4rem 8rem;
}

.post-ol {
  list-style-type: square;
}

.post-list-style-type-decimal {
  list-style-type: decimal;
}

.post-lower-latin {
  list-style-type: lower-latin;
}
/***********************************
FULL PAGE MENU
***********************************/
.menu-column {
  display: flex;
  flex-direction: column;
}

.full-page-menu {
  margin-left: 20% !important;
}

.fullpage-item_,
.menu-column span {
  font-size: 34px !important;
  font-weight: bold !important;
  color: #709ec9 !important;
}
.fullpage-item {
  font-size: 34px !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.8 !important;
  color: black;
}

.fullpage-item:hover {
  text-decoration: underline;
}

.fp-item:hover {
  text-decoration: underline;
  color: #000 !important;
}
.bm-menu-wrap {
  // z-index: 0 !important;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: #f0f0f0;
}

.bm-cross-button {
  margin-right: 50px;
  color: #000;
  left: 20px;
  margin-top: 10px;
}
.bm-cross-button svg {
  margin-right: 5px;
  color: #709ec9;
}

.bm-btn-home {
  margin-left: 15px;
  color: #131a27 !important;
}

.bm-cross-button a {
  color: #000;
  left: 30px;
  margin-top: 0px;
  font-weight: bold;
}

.bm-menu .bm-item-list {
  padding: 7rem 0;
}

.bm-menu .bm-item-list .row {
  display: flex !important;
  justify-content: space-around !important;
}

.bm-menu .bm-item-list a {
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: #131a27;
}

.fullpage-item_underline {
  text-decoration: underline $secundario5;
}

.dropdown-menu-full-page {
  position: absolute;
  top: 4rem;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #f0f0f0 !important;
  border: none !important;
}

// .bm-overlay {
//   width: 10px !important;
// }

.bm-burger-button {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  top: 15px;
  left: 30px;
}

#react-burger-menu-btn {
  width: 20px !important;
}

.spinner {
  height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #131a27;
  z-index: 99999;
  flex-direction: column;
  padding: 0 !important;
  position: fixed;
  top: -10px;
  right: 0px;
}

.spinner::-webkit-scrollbar {
  display: none;
}

.screen-logo {
  width: 400px;
  margin: 0 !important;
  @media (max-width: 768px) {
    width: 320px;
    padding-left:  12px;
    padding-right: 12px;
  }
}

.spinner span {
  font-size: 2rem;
  animation: fade 1s linear 0s infinite;
  padding: 1rem;
}

.half-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #4a9fe9;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
}

.completed {
  font-size: 2rem;
  color: #03fc4e;

  animation: bigger 1s linear;
}

@keyframes bigger {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(2);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate__delay-1ms {
  -webkit-animation-delay: 1ms;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: 0s 1ms;
  animation-delay: var(--animate-delay);
}

.animate__delay-2ms {
  -webkit-animation-delay: 2ms;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: 0s 2ms;
  animation-delay: var(--animate-delay);
}
.animate__delay-3ms {
  -webkit-animation-delay: 3ms;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: 0s 3ms;
  animation-delay: var(--animate-delay);
}

.animate__delay-500ms {
  -webkit-animation-delay: 2000ms;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: 2000ms;
  animation-delay: var(--animate-delay);
}

@font-face {
  font-family: 'Flame';
  src: url('./../../assets/fonts/Flama.otf') format('opentype');
}

* {
  // font-family: 'Flame', 'Roboto', Arial, sans-serif;
}
ul {
  padding-left: 16px;
}

.paddingRowContainer {
  padding-top: 20px;
  // margin-right: initial;
}

.objectives-paragraph {
  @media (max-width: 575px) {
    a {
      font-size: 8px;
    }
  }
}
