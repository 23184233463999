.home-main {
  align-items: center;
  display: flex;
  justify-content: left;
  padding: 3% 6% 3% 6%;
}
.home-main-links {
  align-items: center;
  display: center;
  justify-content: left;
  padding: 3% 6% 3% 6%;
}
.home-main-counter {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1% 6% 1% 6%;
}
.home-main-content {
  align-items: center;
  justify-content: left;
  padding: 3% 6% 1% 6%;
  background-color: #e5e5e5;
}
.col-home-card-right {
  justify-content: right !important;
  min-width: 325px;
  display: grid;
}

.home-main-content-title {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #060707;
}

.home-subtitle-content {
  width: 100%;
  margin: 0px 0px 0 0px;
  padding-right: 3%;
  display: grid;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: left;
  color: #000;

  @media screen and (max-width: 768px) {
    line-height: 29px;
    margin-bottom: 10px;
  }
}

.home-main-ods {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2% 6% 1% 6%;

  @media screen and (max-width: 768px) {
    display: table-caption;
    margin: 1rem 2.5rem;
  }
}
.text-metodology{
  font-weight: bold;
  color: black;
  width: 100%;
  font-size: 20px;
  @media only screen and (max-width: 640px) {
    padding: 0 40px;
    font-size: 25px;
  }
}

.home-metodology {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1% 1%;
  background: #e5e5e5;
  @media screen and (max-width: 575px) {
    padding: 1em 0;
    margin: 0 !important;
  }
}

.home-aquote {
  padding: 1% 0% 2% 5%;
  background: #e5e5e5;
  width: 101%;
  font-size: 19px;
}

.home-title-content-p {
  line-height: 30px;
  margin-right: -100px;
  font-weight: 300;
  margin-top: -20px;
  margin-bottom: -17px;
}

.home-card-metodology {
  width: 235px;
  min-width: 230px;
  background: #3f6b95;
  min-height: 170px;
  margin: 0px 10px 26px 16px;
  border-radius: 15px;
  font-size: 20px;
  line-height: 22px;
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer !important;
    background: lighten($color: #3f6b95, $amount: 10) !important;
  }
  p {
    text-align: center;
  }
}

.home-card-metodology-number {
  font-size: 60px;
  font-weight: 500;
  margin-top: 42px;
}

.home-card-metodology-circle {
  width: 102px;
  background: #3f6b95;
  padding: 13px 40px 13px 39px;
  margin: 50px 170px;
  border-radius: 100%;
  font-size: 47px;
  color: #131a27;
}

.home-row-metodology {
  width: auto;
  height: 23px;
  position: absolute;
  margin: 75px 16px -280px -214px !important;

  @media (max-width: 1409px) {
    display: none;
  }
}
.home-row-metodology-2 {
  width: auto;
  height: 23px;
  position: absolute;
  margin: 75px 16px -260px 208px !important;

  @media (max-width: 1409px) {
    display: none;
  }
}

.mapeo-circle {
  margin-top: -35px;
}
.implementacion-circle {
  margin-top: -35px;
}
.priorizacion-circle {
  margin-top: -35px;
}

.home-label-h1 {
  -webkit-text-stroke: 1px $secundario5;
  font-size: 50px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $secundario5;
  float: right;
  margin-right: 100px;
  margin-top: 30px;
}
.articleBody {
  font-size: 19px;
  text-align: justify;
  margin-top: 20px;
}

.flashcard-detail-front-footer-right {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: grid;
  width: 318px;
  padding-bottom: 10px;
}

.flashcard-detail-row {
  padding: 8px 7px;
}

.home-label-h2 {
  -webkit-text-stroke: 1px $secundario5;
  font-size: 33px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $secundario5;
  float: left;
  margin-right: 100px;
  margin-bottom: 20px;
  @media only screen and (max-width: 640px) {
    font-size: 25px;
    text-align: justify;
    margin-right:50px;

  }
}

.home-row {
  width: 100%;
  display: inline-block;
}

.home-elipse {
  width: 298px;
  height: 298px;
  margin: 40px -60px 120.5px 26.5px;
  padding: 100px 78px 138px 70px;
  border-radius: 100%;
  border-width: 3px;
  background-color: #709ec987;
}

.home-elipse-light {
  width: 298px;
  height: 298px;
  margin: 40px -60px 120.5px 26.5px;
  padding: 100px 78px 138px 70px;
  border-radius: 100%;
  border-width: 3px;
  background-color: #709ec9;
}

.home-text-circle {
  width: 127px;
  height: 89px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 29px;
  font-weight: 300;
  font-stretch: normal;
  padding-left: -6px;
  margin-left: 1px;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

img.home-img-eje {
  display: flex;
  float: right;
}

.home-text-circle2 {
  width: 127px;
  height: 89px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 29px;
  font-weight: 300;
  font-stretch: normal;
  margin-left: -15px;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

// Matrix
.title-h1 {
  width: 660px;
  height: 88px;
  margin: 83px 100.5px 2px -2px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.84;
  letter-spacing: normal;
  text-align: left;
  color: #709ec9;
  &:hover {
    color: darken(#709ec9, 0.8);
  }
}

.line-h1 {
  width: 100%;
  height: 3px;
  margin: 20px 0px;
  background-color: #709ec9;
}

.text-h1 {
  width: 674px;
  height: 99px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 22px;
  display: block;
  margin-top: 7px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

//Slider
.Conoce-las-flashcards-con-mayor-ranking {
  height: 25px;
  margin: 104px 41.2px 12px 1px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Trazado-708 {
  width: 13.2px;
  height: 26.4px;
  margin: 64.1px 23.3px 64.1px 0;
  border: solid 2px #fff;
}

.Elipse-121 {
  width: 154.7px;
  height: 154.7px;
  margin: 0 16.7px 0 0;
  padding: 61.9px 10.4px 61.9px 14.2px;
  border: solid 1px #fff;
  background-color: #fff;
  border-radius: 100%;
}

.Grupo-938 {
  width: 1040px;
  height: 154.7px;
  margin: 61.8px 0 80px 0;
}

.Logos_CCE1 {
  width: 130.1px;
  height: 30.9px;
}

.home-company-container {
  margin-top: 50px;
  margin-bottom: 70px;
}

.home-company-circle {
  margin: 5px 0px -100px 0px;
  background-color: #709ec9 !important;
}

.cards-list-goodpractices-row {
  // height: calc(100vh - 500px);
  min-height: 580px;
  justify-content: left;
  margin-top: 2rem;
}

.button {
  display: inline-block;
  margin-top: 20px;
  padding: 8px 25px;
  border-radius: 4px;
}
.button-primary {
  position: relative;
  background-color: #c0ca33;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
  transition: color 0.3s ease-in;
  z-index: 1;
}
.button-primary:hover {
  color: #c0ca33;
  text-decoration: none;
}
.button-primary::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  border-radius: 4px;
  opacity: 0;
  -webkit-transform: scaleX(0.8);
  -ms-transform: scaleX(0.8);
  transform: scaleX(0.8);
  transition: all 0.3s ease-in;
  z-index: -1;
}
.button-primary:hover::after {
  opacity: 1;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.overlay::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.header-area {
  position: relative;
  height: 100vh;
  background: #5bc0de;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repear;
  background-size: cover;
}
.banner {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  color: #fff;
  text-align: center;
  z-index: 1;
}
.banner h1 {
  font-weight: 800;
}
.banner p {
  font-weight: 700;
}
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  // padding: 6px 34px 0 50px;
  width: 100%;
  transition: background 0.6s ease-in;
  z-index: 99999;
  padding: 1rem;
  font-size: 11px;
  display: flex;
  justify-content: space-around;

  // Medium devices (tablets, 768px and up) 2
  @media (min-width: 1800px) {
    font-size: 16px;
  }
}

.navbar .navbar-toggler {
  position: relative;
  height: 50px;
  width: 50px;
  border: none;
  cursor: pointer;
  outline: none;
}
.navbar .navbar-toggler .menu-icon-bar {
  position: absolute;
  left: 15px;
  right: 15px;
  height: 2px;
  background-color: #fff;
  opacity: 0;
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  transition: all 0.3s ease-in;
}
.navbar .navbar-toggler .menu-icon-bar:first-child {
  opacity: 1;
  -webkit-transform: translateY(-1px) rotate(45deg);
  -ms-sform: translateY(-1px) rotate(45deg);
  transform: translateY(-1px) rotate(45deg);
}
.navbar .navbar-toggler .menu-icon-bar:last-child {
  opacity: 1;
  -webkit-transform: translateY(-1px) rotate(135deg);
  -ms-sform: translateY(-1px) rotate(135deg);
  transform: translateY(-1px) rotate(135deg);
}
.navbar .navbar-toggler.collapsed .menu-icon-bar {
  opacity: 1;
}
.navbar .navbar-toggler.collapsed .menu-icon-bar:first-child {
  -webkit-transform: translateY(-7px) rotate(0);
  -ms-sform: translateY(-7px) rotate(0);
  transform: translateY(-7px) rotate(0);
}
.navbar .navbar-toggler.collapsed .menu-icon-bar:last-child {
  -webkit-transform: translateY(5px) rotate(0);
  -ms-sform: translateY(5px) rotate(0);
  transform: translateY(5px) rotate(0);
}
.navbar-dark .navbar-nav .nav-link {
  position: relative;
  color: #fff;
  font-size: 1.6rem;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}
.navbar .dropdown-menu {
  background-color: #608fb7;
  min-width: 135px;
  border-radius: 0;
  border-top: solid 3px #a6c6de;
}
.navbar .dropdown-menu .dropdown-item {
  position: relative;
  padding: 5px 10px;
  color: #fff;
  font-size: 11px;
  transition: color 0.2s ease-in;
  @media (min-width: 1800px) {
    font-size: 16px;
  }
}

.navbar .dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: $primario;
}

nav,
div[class='row'] {
  padding-top: 10px;
}

.navbar .dropdown-menu .dropdown-item:hover::before {
  opacity: 1;
}
.navbar.fixed-top {
  position: fixed;
  -webkit-animation: navbar-animation 0.6s;
  animation: navbar-animation 0.6s;
  background-color: rgba(0, 0, 0, 0.9);
}
.navbar.fixed-top.navbar-dark .navbar-nav .nav-link.active {
  color: #c0ca33;
}
.navbar.fixed-top.navbar-dark .navbar-nav .nav-link::after {
  background-color: #c0ca33;
}
.content {
  padding: 120px 0;
}
@media screen and (max-width: 768px) {
  .navbar-brand {
    margin-left: 20px;
  }
  .navbar-nav {
    padding: 0 20px;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .navbar.fixed-top .navbar-nav {
    background: transparent;
  }
}

.class-mobile {
  display: none;
  @media (max-width: 575.98px) {
    display: flex;
  }
}

@media screen and (min-width: 767px) {
  .banner {
    padding: 0 150px;
  }
  .banner h1 {
    font-size: 5rem;
  }
  .banner p {
    font-size: 2rem;
  }
  .navbar-dark .navbar-nav .nav-link {
    padding: 23px 15px;
  }
  .navbar-dark .navbar-nav .nav-link::after {
    content: '';
    position: absolute;
    bottom: 15px;
    left: 30%;
    right: 30%;
    height: 1px;
    background-color: #fff;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    transition: transform 0.1s ease-in;
  }
  .navbar-dark .navbar-nav .nav-link:hover::after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  .dropdown-menu {
    min-width: 200px;
    -webkit-animation: dropdown-animation 0.3s;
    animation: dropdown-animation 0.3s;
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
  }
}
@-webkit-keyframes navbar-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes navbar-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes dropdown-animation {
  0% {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
  }
  75% {
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
  }
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes dropdown-animation {
  0% {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
  }
  75% {
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
  }
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.nav-link {
  font-weight: 300;
}

.nav-link:hover {
  background-color: transparent;
  color: $secundario4;
}

nav#pageHeader {
  box-shadow: 5px 5px 11px -8px #7575750f;
}

// BANNER
.background-primary {
  background: $primary !important;
}

.FC_Mocukup_LandingPage {
  width: 100%;
  height: calc(100vh - 1px);
  max-height: 800px;
  min-height: 780px;
  padding: 80px 45px 85px 85px;
  margin: 0px;
  background: #e4e4e4;
}

.container.cards__row {
  min-height: calc(100vh - 1vh);
  max-height: calc(100vh - 2vh);
  background-color: #fff;
  padding: 0px;
  margin-right: 0px;
  padding-right: 0px;
  max-width: inherit;
}

.goodpractice-filter-div-content {
  width: 90%;
  margin-right: 10px;
  @media screen and (max-width: 575.98px) {
    margin-right: 10px;
    justify-content: center;
  }
}

.headerImpl-grid {
  @media screen and (max-width: 575.98px) {
    .btn-secondary {
      background-color: transparent;
      border: none;

    }
  }
}

.cards__row-cont {
  margin-left: 0px;
  min-height: 570px;
  background-color: #131a27;
}

.user__contact-form {
  min-height: calc(100vh - 300px);
}

.container.header-color.conatiner-light {
  max-height: 769px;
}

.FLASHCARDS-CLIMB {
  height: 160px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #699ac6;
}
.FLASHCARDS-CLIMB-REGISTER {
  height: 160px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  margin-left: 35px;
  letter-spacing: normal;
  text-align: center;
  color: #699ac6;
}
.FLASHCARDS-CLIMB-REGISTER-OBS {
  margin: 0 0 10px 38px;
  font-weight: 300;
  font-size: 20px;
}

.form-control-edit-gender-content {
  width: 12px;
}
.form-control-edit-gender-label {
  width: 245px;
  margin-top: -3px !important;
  padding-top: 0px !important;
  padding-bottom: 8px !important;
}
.form-control-edit-gender-radio {
  width: 20px !important;
  height: 27px !important;
  vertical-align: top;
  margin-left: 0px !important;
  margin-right: 8px;
}
.form-control-edit-gender-input {
  width: 12px;
}

.form-control-edit-gender-title {
  width: 12px;
  height: 14px;
  margin-top: 14px !important;
}

.home-col-pacto-img,
.home-col-ods-img {
  max-width: 118px;
  padding: 0;
  @media screen and (max-width: 768px) {
    margin: auto;
  }
}
.home-row-ods-div {
  padding: 27px 0 0px 22px;
  @media (max-width: 768px) {
    display: flex;
  }
  @media (min-width: 769px) {
    display: none;
  }
}

.home-row-ods-img {
  @media (max-width: 768px) {
    margin-left: 10px;
  }
}

.home-row-ods-label {
  justify-content: center;
  padding: 18px 0px 18px 0px;
}
.home-col-pacto-img {
  max-width: 94px;
  @media screen and (max-width: 768px) {
    margin: auto;
  }
}
.home-col-text-title {
  text-align: center;
  max-width: 726px;
  padding-right: 15px !important;
  margin: 0px 0px 0px -8px !important;
}

.FLASHCARDS-CLIMB-HOME {
  // width: 453px;
  height: 160px;
  // margin: 155px 610px 14px 0px;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #699ac6;
}

.home-logo-pacto-img,
.home-ods-pacto-img {
  width: auto;
  justify-content: right;
  margin: -27px 0 10px 17px !important;
  padding: 0px !important;
}
.home-logo-pacto-img {
  height: 90px;
}
.home-ods-pacto-img {
  height: 73px;
}

.home-subtitle {
  width: 100%;
  // height: 231px;
  margin: 14px 0px 0 0px;
  padding-right: 20%;
  display: grid;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    line-height: 29px;
    margin-bottom: 10px;
  }
}

a[href^='mailto:'] {
  color: #365cbe;
  font-weight: 400;
}

.home-subtitle-home {
  width: 100% !important;
  margin: 6px 0px 5px 0px;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: normal;
  text-align: center;
  max-width: 848px;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    line-height: 29px;
    margin-bottom: 10px;
  }
}

.home-subtitle-home2 {
  width: 100% !important;
  margin: -26px 0px 25px 0px;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 35px;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: center;
  max-width: 1248px;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    line-height: 29px;
    margin-bottom: 10px;
    font-size: 24px;
    padding: 0 49px 0 40px;
  }
}

.home-row-counter {
  background: #0a121d;
  color: #131a27;
  border-radius: 5px;
  vertical-align: middle;
  width: auto;
  margin-top: 30px;
  padding: 73px 33px 59px 35px;
  cursor: pointer;
  margin-bottom: 12px;
  font-weight: 500;
  transition: background 0.3s ease;
  box-shadow: 4px 3px 11px 0px rgba(35, 35, 35, 0.115);

  &:hover {
    cursor: pointer;
    color: #131a27 !important;
    background: lighten($color: #223c4d, $amount: 5);
  }
  @media (max-width: 767px) {
    margin: 38px 10px 16px 0;
    max-width: 308px;
    padding-left: 51px;
    padding-top: 45px;
  }
}

.home-h3-link {
  font-weight: 300;
  color: #fff;
  font-size: 18px;
  background: #5a82ff;
  padding: 9px 19px;
  text-decoration: none;
  margin: 40px 35px 0 46px;
  text-align: center;
  border-radius: 25px;
  box-shadow: 3px 4px 10px -3px #39393966;
  transition: all 0.5s ease;
  &:hover {
    background: darken(#5a82ff, 3);
    box-shadow: 3px 4px 10px -3px #3939394f;
  }
}
.home-h3-link-home {
  font-weight: 300;
  color: #fff;
  font-size: 18px;
  background: #5a82ff;
  padding: 12px 19px;
  text-decoration: none;
  margin: -1px 135px 0 146px;
  text-align: center;
  border-radius: 25px;
  box-shadow: 3px 4px 10px -3px #39393966;
  transition: all 0.5s ease;
  &:hover {
    background: darken(#5a82ff, 3);
    box-shadow: 3px 4px 10px -3px #3939394f;
  }
}

.home-count-number-text-title {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Roboto', Arial, sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-bottom: 180px;
  margin-left: 210px;
  position: absolute;
  @media (max-width: 990px) {
    justify-content: center;
    display: contents;
  }
}
.home-count-number-text-subtitle {
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-bottom: -135px;
  margin-left: 213px;
  position: absolute;
  @media (max-width: 990px) {
    top: 898px;
    width: 100%;
    margin-right: 19px;
  }
}
.home-count-number-text-subtitle2 {
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-bottom: -135px;
  margin-left: 213px;
  position: absolute;
  @media (max-width: 990px) {
    top: 78px;
    margin-left: -7px;
    width: 228px; 
    margin-right: -18px;
    position: relative; 
  }
}

.home-count-number-text {
  text-align: left;
  vertical-align: middle;
  font-size: 21px;
  max-width: 287px;
  margin-left: -15px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: #aecfe6;
  line-height: 23px;
  padding: 0px;
  padding-top: 14px;

  @media (max-width: 768px) {
    font-size: 26px;
    text-align: center;
    padding-left: 25px;
    line-height: 27px;
  }
  @media (max-width: 991px) {
    font-size: 26px;
    text-align: center;
    padding-left: 25px;
    padding-bottom: 25px;
    line-height: 27px;
  }
}
.home-count-number {
  font-size: 110px;
  text-align: center;
  font-weight: 500;
  margin-left: 17px;
  margin-right: 16px;

  @media screen and (max-width: 767px) {
    // padding-left: 20px;
    font-size: 62px;
    margin-left: 0;
  }
}

.home-ods-svg-title {
  width: 400px;
  height: auto;
  margin: 0px;
  margin-left: 0px !important;

  @media (max-width: 991px) {
    width: 80vw !important;
    padding: 0px 20px 0px 0px;
  }
}
.home-ods-svg-div {
  width: 100%;
  justify-content: center;
  display: grid;
  margin: 41px 0px 5px 0px;
}
.home-ods-svg-div2 {
  width: 100%;
  font-family: 'Roboto', Arial, sans-serif;
  justify-content: center;
  display: grid;
  font-weight: bold;
  font-size: 108px;
  margin: 41px 0px 5px 0px;

  @media (max-width: 520px) {
    padding-right: 47px;
  }
}

.home-count-h3-number {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0px;
  color: #aecfe6;
  min-width: 205px;
  @media (max-width: 768px) {
    margin-bottom: -18px;
  }
}

//!-- CONTADOR PEQUEÑO DE PÁGINA SÚMATE ---------------//

.home-count-h3-number-small {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0px;
  // min-width: 105px;
}
.home-count-cell-small {
  width: auto;
  padding: 0px 45px 0px 50px;
  @media (max-width: 768px) {
    width: 190px;
    padding: 0px;
  }
}

.home-count-number-small {
  font-size: 80px;
  text-align: center;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 60px;
    text-align: right;
  }
}

.home-title-sumate {
  margin: 5px 0% 0px 0px;
  width: 300px;
  display: flex;
  float: left;
  font-size: 72px !important;
}

.home-count-number-text-small {
  text-align: left;
  vertical-align: middle;
  font-size: 26px;
  width: 350px;
  margin-left: -25px;
  font-weight: 400;
  line-height: 38px;
  padding: 0;
  margin-right: 32px;
  margin-top: 20px;
  @media (max-width: 767px) {
    font-size: 18px;
    max-width: 160px;
    line-height: 23px;
  }
}

.home-row-counter-small {
  background: #699ac6;
  color: #131a27;
  border-radius: 140px;
  vertical-align: middle;
  width: auto;
  margin-top: 30px;
  margin-bottom: 12px;
  font-weight: 500;

  @media (max-width: 767px) {
    margin-left: -40px;
    margin-right: -50px;
  }
}

//!---------------------------------------------------//

.center-col {
  display: grid;
  align-items: center;
  justify-items: center;
}

.center-col-card {
  min-width: 418px;
  margin-bottom: 90px;
  @media only screen and (max-width: 640px) {
    margin-bottom: 0;
  }
}

.without-padding {
  padding: 0px;
}
.without-margin {
  margin: 0px;
}

.home-subtitle .text-style-1 {
  font-weight: bold;
  color: #4c698a;
}

img.home-banner-front-icon {
  width: auto;
  max-height: 700px;
  margin-left: 0px !important;
  max-width: calc(100vw - 91%);
}

span.text-style-1 {
  margin-top: 33px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .FC_Mocukup_LandingPage img.home-banner-front-icon {
    max-height: 341px;
  }
}
@media (max-width: 991px) {
  .FC_Mocukup_LandingPage {
    max-height: 1120px;
  }
}

.col-home-card {
  min-width: 325px;
  justify-content: center;
  display: grid;
  @media screen and (max-width: 991px) {
    justify-content: center !important;
  }
}

.row-home-card {
  justify-content: center !important;
}

.home-main-inks{
  padding-top: 0;
  @media only screen and (max-width: 640px) {
    padding: 0 25px;
  }
}
.company-card-body {
  width: 466px;
  display: inline-block;
  margin: 2px 5px 17px 0px;
  border: 1px solid #699cc6;
  border-radius: 5px;
  height: 135px;
  background-color: #ecf3f8;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  &:hover {
    background-color: #f2f6f8;
  }
  @media (max-width: 1199px) {
    margin: 39px 0px 65px 0px;
  }
  @media (max-width: 520px) {
    width: 91vw;
    margin-left: 3vw;
    margin-right: 6vw;
  }
}
.company-card-body2 {
  width: 300px;
  display: inline-block;
  color: #000;
  margin: 2px 5px 17px 0px;
  border: 1px solid #699cc6;
  border-radius: 5px;
  height: 100px;
  background-color: #ecf3f8;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  &:hover {
    background-color: #f2f6f8;
  }
  @media (max-width: 1199px) {
    margin: 39px 0px 65px 0px;
  }
  @media (max-width: 520px) {
    width: 350px;
    margin-left: 10px;
  }
}
.company-card-body-home {
  width: 961px;
  display: inline-block;
  margin: 39px 6px 65px 0px;
  border-radius: 8px;
  height: 135px;
  border: 1px solid #699cc6;
  background-color: #ecf3f8;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);

  &:hover {
    background-color: #f2f6f8;
  }

  @media (max-width: 1199px) {
    margin: 39px 0px 65px 0px;
  }
  @media (max-width: 520px) {
    width: 91vw;
    margin-left: 3vw;
    margin-right: 6vw;
    padding-right: 20px;
  }
}
.font-weight-500 {
  font-weight: 500;
}

.home-filter-bue {
  width: 418px;
  position: absolute;
  height: 112px;
  background: #4c698a9e;
  border-radius: 0 0 18px 18px;
}

.company-header-gta-title-2 {
  font-size: 19px;
  color: #060707;
  padding: 0px 3px 7px 3px;
  font-weight: 400;
  line-height: 27px;
}
.company-header-gta-title-2-home {
  font-size: 19px;
  color: #bad4e9;
  padding: 15px 0px 7px 3px;
  font-weight: 400;
  text-align: left;
  line-height: 25px;

  @media (max-width: 520px) {
    text-align: center;
    margin-left: -40px;
  }
}

.home-div-principal {
  min-height: calc(100vh - 300px);
  height: 1640px;
  padding-top: 30px;
  width: 100%;
  padding-right: 0;

  @media (min-width: 576px) and (max-width: 991px) {
    height: 1769px !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 1069px !important;

    .navbar {
      font-size: 11px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0rem !important;
      padding-left: 0rem !important;
    }
  }
}
.home-div-principal-img {
  height: 695px;
  padding-top: 0px;
  display: grid;
  justify-content: center;
  margin-top: -30px;
  width: 100%;
  background: #e6e6e6;
  // background-image: url('../../assets/img/PremiosLogra-Carrusel.jpg');
  background-size: cover;
  @media (max-width: 991px) {
    height: 722px;
    padding: 26px 4px 7px 0px;
  }
}
.form-metodology-handy {
  width: auto;
  height: 54px;
  position: absolute;
  margin: 15px 0 0 -9px;
}

.home-points-text {
  font-size: 22px;
  color: #77a5cb;
  font-weight: 700;
}
.home-points-text2 {
  font-size: 22px;
  color: #77a5cb;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 18px;
  @media (max-width: 520px) {
    margin-left: -130px;
  }
}
.home-points-text2-margin {
  @media (max-width: 520px) {
    margin-left: -200px;
  }
}

.company-card-img {
  @media (max-width: 520px) {
    padding-left: 20px !important;
  }
}
.company-card-img2 {
  @media (max-width: 520px) {
    padding-left: 150px !important;
  }
}
