.option-grid{
  margin: 7rem 0 4rem 3rem;
}

.option-title{
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.option-select  {
 margin: 1rem 0;
}

.option-select label {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
